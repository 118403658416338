import React from "react";

import Button from "../Button";

import { Close } from "@mui/icons-material";
import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function DeleteBoxModal({ setOpen, id }) {

  const { setBoxes, cart, setCart } = useGlobalState();

  return (
    <div id="delete-box" >
      <div className="content">
        <div className="close" onClick={() => setOpen(false)}>
          <Close />
        </div>
        <h3>Tem certeza que deseja excluir essa caixinha?</h3>
        <Button
          kind={"primary"}
          label="Sim"
          onClick={() => {
            if (cart.id === id) setCart({ id, itens: [], delivery: cart.delivery, city: cart.city, store: cart.store });
            setBoxes(oldBoxes => oldBoxes.filter(b => b.id !== id))
            setOpen(false);
          }}
        />
      </div>
    </div>
  );
}
