import React, { useState } from "react";

import AddItensOption from '../AddItensOption';
import GiftOption from '../GiftOption';
import BoxItens from '../BoxItens';
import DeleteBoxModal from '../DeleteBoxModal';

import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { formatNumber, getCartTotal } from '../../helpers/cart';

import "./style.css";

export default function BoxMultiple({ cart }) {
    const [ open, setOpen ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)

    const total = getCartTotal([ cart ]).toFixed(2)

    function handleClick() {
        setOpen(oldOpen => !oldOpen)
    }
    return (
        open ? <>
            <div className='box-multiple-open'>
                < div className='header' >
                    <div>
                        <p className='name'>{cart.name} -</p>
                        <p className='number'>{formatNumber(total)}</p>
                    </div>
                    <p className='delete-box' onClick={() => setOpenModal(true)}>excluir</p>
                    <div onClick={handleClick} className='expand'>
                        <ExpandLess />
                    </div>
                </div >
                <BoxItens cart={cart} isMultiple={true} />
            </div >
            <AddItensOption cart={cart} />
            <GiftOption cart={cart} />
            {openModal && <DeleteBoxModal id={cart.id} setOpen={setOpenModal} />}
        </> :
            <div className='box-multiple'>
                <div>
                    <p className='name'>{cart.name} -</p>
                    <p className='number'>{formatNumber(total)}</p>
                </div>
                <p className='delete-box' onClick={() => setOpenModal(true)}>excluir</p>
                <div onClick={handleClick} className='expand'>
                    <ExpandMore />
                </div>
                {openModal && <DeleteBoxModal id={cart.id} setOpen={setOpenModal} />}
            </div>
    );
}
