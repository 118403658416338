import React, { useEffect } from "react";

import { Input } from "antd";

import $ from "jquery";
import "jquery-mask-plugin";

import "antd/dist/antd.css";
import "./style.css";

export default function InputComponent({
  label,
  icon,
  placeholder,
  type,
  id,
  value,
  onChange,
  onBlur,
  lineColor,
  rightIcon,
}) {
  useEffect(() => {
    $("#cepInput").mask("00000-000");
    $("#cpf").mask("000.000.000-00");
    $("#expirationDate").mask("00/00");
    $("#phone").mask("(00) 00000-0000");
  }, []);

  return (
    <div className="field">
      <label>{label}</label>
      <div className="input">
        {icon}
        {type == "password" ? (
          <Input.Password
            type={type}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        ) : (
          <Input
            type={type}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete="none"
          />
        )}
        {rightIcon}
      </div>
      <div className="line" style={{ backgroundColor: lineColor }} />
    </div>
  );
}
