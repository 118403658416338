import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";

import Brand from "../../components/Brand";
import Button from "../../components/Button";
import FidelityModal from "../../components/FidelityModal";

import { useGlobalState } from "../../hooks/globalState";

import { showNotification } from "../../helpers/notification";
import { sleep } from "../../helpers/vars";
import api from "../../services/api";

import "./style.css";

export default function Success() {
  const location = useLocation();
  const { isPix, base64, code, fidelity_count, orderId } = location.state
    ? location.state
    : {};

  const { isMobile, banner, isLoggedIn, setUser } = useGlobalState();
  const navigate = useNavigate();

  const [pending, setPending] = useState(false);
  const [open, setOpen] = useState(true);

  async function handleCopyCode() {
    if ("clipboard" in navigator) await navigator.clipboard.writeText(code);
    else document.execCommand("copy", true, code);
    showNotification("Código PIX copiado com sucesso!", "");
  }

  async function monitorPayment() {
    const { data } = await api.get(`/order/monitor/${orderId}`);
    if (data.status !== 1) {
      await sleep(30000);
      monitorPayment();
    } else setPending(false);
  }

  useEffect(() => {
    setPending(isPix);
    if (isPix) monitorPayment();
    if (!isLoggedIn) setUser(null);
  }, []);

  return (
    <div
      id="success"
      className="imageBg"
      style={{
        backgroundImage: `url(${isMobile ? banner.url_mobile : banner.url})`,
      }}
    >
      <div className="welcome-success">
        <Brand />
      </div>
      <div className="brand-success common">
        <div className="content-area">
          <div className="order-info">
            <div className="order-status">
              <div className="header">
                <h3>
                  {pending
                    ? "Pedido recebido!"
                    : "Pedido concluído com sucesso!"}
                </h3>
                {pending && (
                  <h4>
                    Após o pagamento, seu pedido será concluído e você poderá
                    acompanhá-lo na página Meus Pedidos
                  </h4>
                )}
              </div>
              <div className="infos">
                <h3 id="order-number">Número do pedido:</h3>
                <h1>{orderId}</h1>
              </div>
            </div>
            {pending && (
              <div className="infos pix">
                <h3>
                  Realize o pagamento <br /> com o QrCode abaixo:
                </h3>
                <img src={`data:image/png;base64,${base64}`} alt="qrcode" />
                <h4 onClick={handleCopyCode}>
                  Ou clique aqui para copiar o código
                </h4>
              </div>
            )}
          </div>
          <Button
            kind={"primary"}
            label="Voltar para o início"
            onClick={() => navigate("/")}
          />
          {/* <Button kind={"secondary"} label="Revisar/editar pedido" /> */}
          {open && <FidelityModal setOpen={setOpen} count={fidelity_count} />}
        </div>
      </div>
    </div>
  );
}
