import api from "../services/api";
import { addItemToCart } from './cart';

export async function getCupom(code, isStandalone) {
    const { data } = await api.post(`/order/cupom`, { code });
    if (data.cupom.length === 0) return null;
    const cupom = data.cupom[ 0 ]
    const appOnly = cupom.app_only === 1
    if (checkDate(cupom) && (!appOnly || isStandalone)) return cupom
}

function checkDate(cupom) {
    const validFrom = parseDatetime(cupom.valid_from)
    const validTo = parseDatetime(cupom.valid_to)
    const today = new Date()
    return today >= validFrom && today <= validTo
}

function parseDatetime(date) {
    let dateTimeParts = date.split(/[- :]/);
    dateTimeParts[ 1 ]--;
    return new Date(...dateTimeParts);
}

export function applyPercentageCupom(cupom, total) {
    let remaining = 1 - (cupom.value / 100)
    return { discount: ((cupom.value / 100) * total), total: remaining * total }
}

export function applyFreeItemDiscount(boxes, cupom, total) {
    let values = boxes
        .reduce((previousCartTotal, cart) =>
            [ ...previousCartTotal, ...cart.itens.reduce(
                (previousValue, currentValue) => [ ...previousValue, parseFloat(currentValue.price.replace(",", ".")) * currentValue.qtt ]
                ,
                []
            ) ]
            , []).sort((a, b) => b - a).slice(0, cupom.value ?? 1)
    const disc = values.reduce((prev, curr) => prev + curr, 0)

    return { discount: disc, total: (total - disc) }
}

export function applyGiftDiscount(gift, updateCart, cart) {
    addItemToCart(gift, cart, updateCart)
    return { discount: parseFloat(gift.price.replace(",", ".")) }
}