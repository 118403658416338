import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../Button";

import { Close, EditRounded } from "@mui/icons-material";
import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function FinishOrder({ setShowFinishModal, activeUser }) {

  const { cart, updateCart } = useGlobalState();

  const navigate = useNavigate();

  function updateName(value) {
    updateCart("name", `Caixinha de ${value}`);
  }

  return (
    <div id="finish-order" >
      <div className="content">
        <div className="close" onClick={() => setShowFinishModal(false)}>
          <Close />
        </div>
        <h3>{activeUser ? "Qual será o nome da sua caixinha?" : "Para concluir seu pedido, qual será o nome da sua caixinha?"}</h3>
        <div className="search">
          <input
            placeholder={`Nome da Caixinha`}
            value={cart.name ? cart.name : ""}
            onChange={(e) => updateCart("name", e.target.value)}
          />
          <EditRounded />
        </div>
        <Button
          kind={"primary"}
          label={activeUser ? "Continuar editando minha caixinha" : "Fechar minha caixinha"}
          onClick={() => {
            if (!cart.name) updateCart("name", `Nome da Caixinha`);
            else updateName(cart.name);
            if (activeUser) setShowFinishModal(false)
            else navigate("/carrinho");
          }}
        />
      </div>
    </div>
  );
}
