import React, { useEffect } from "react";

import { DeleteOutline, Upcoming } from "@mui/icons-material";

import { useGlobalState } from "../../hooks/globalState";
import { showNotification } from "../../helpers/notification";
import { calculateDeliveryTax } from "../../helpers/cep";
import "./style.css";

export default function DeliveryAdressess({
  setNewAddress,
  setAddress,
  address,
  profile,
  setNewChanges,
}) {
  const { user, cart, updateCart, setUser, setLoading, stores } =
    useGlobalState();

  const addressess =
    user && user.addresses
      ? JSON.parse(user.addresses).filter(
          (a) => a.publicPlace && a.publicPlace !== ""
        )
      : [];

  useEffect(() => {
    if (addressess.length > 0 && !profile) {
      setNewAddress(false);
    }
  }, []);

  function handleSelect(address) {
    if (!profile) {
      setAddress(address);
      setNewAddress(false);
      handleCep(address.cep);
    }
  }

  async function handleCep(cep) {
    const path = window.location.pathname;
    if (cart.delivery === "delivery" && path !== "/perfil/enderecos") {
      setLoading(true);
      let store = stores.find((s) => s.id == cart.store);
      let shippingInfo = await calculateDeliveryTax(cep, store.cep, cart.store);
      if (shippingInfo) updateCart("zipSet", shippingInfo);
      else {
        showNotification(
          "Infelizmente, ainda não entregamos nesse endereço",
          "Por favor, tente novamente."
        );
        setAddress({});
      }
      setLoading(false);
    }
  }

  async function handleDeleteAddress(index) {
    const tmpUser = { ...user };
    const tmpAddresses = JSON.parse(tmpUser.addresses);
    tmpAddresses.splice(index, 1);
    tmpUser.addresses = JSON.stringify(tmpAddresses);
    setUser(tmpUser);
    setNewChanges(true);
  }

  const maskSensitiveInfo = (addressObj) => {
    // Função para mascarar parte do CEP
    const maskCep = (cep) => {
      return cep?.replace(/^(\d{5})/, "*****");
    };

    // Função para mascarar números em geral
    const maskNumbers = (text) => {
      return text?.replace(/\d+/g, "***");
    };

    // Função para mascarar parte do logradouro
    const maskPublicPlace = (publicPlace) => {
      return publicPlace?.replace(/(\d+)/g, "***");
    };

    // Aplicar as máscaras nas partes relevantes do objeto
    const maskedCep = maskCep(addressObj.cep);
    const maskedPublicPlace = maskPublicPlace(addressObj.publicPlace);
    const maskedNumber = maskNumbers(addressObj.number);
    const maskedAdjunct = maskNumbers(addressObj.adjunct);

    // Construir a string final mascarada
    const maskedAddress = maskedAdjunct
      ? `${maskedPublicPlace}, ${addressObj.neighborhood}, número: ${maskedNumber}, ${maskedAdjunct}, ${addressObj.city}, CEP ${maskedCep}`
      : `${maskedPublicPlace}, ${addressObj.neighborhood}, número: ${maskedNumber}, ${addressObj.city}, CEP ${maskedCep}`;

    return maskedAddress;
  };

  return (
    <div className="delivery-adressess">
      <div className="title">
        <h1>
          {profile ? "Endereços de entrega cadastrados" : "Selecione Endereço"}
        </h1>
      </div>
      {addressess.map((addressData, index) => (
        <div
          key={index}
          className={
            (address.cep && address.cep === addressData.cep) || profile
              ? "item selected"
              : "item"
          }
          onClick={() => handleSelect(addressData)}
        >
          <div className="infos">
            <h1>{addressData.name}</h1>
            <h3>{maskSensitiveInfo(addressData)}</h3>
          </div>
          {profile && (
            <div className="actions">
              <div onClick={() => handleDeleteAddress(index)}>
                <DeleteOutline />
              </div>
            </div>
          )}
        </div>
      ))}
      {profile && addressess.length === 0 && (
        <div className="empty">
          <Upcoming />
          <p>Nenhum endereço encontrado</p>
        </div>
      )}
    </div>
  );
}
