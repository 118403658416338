import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../images/icons/logo.svg";
import imageAbout from '../../images/sobre-nos.jpg';
import { DehazeTwoTone } from '@mui/icons-material';

import { useGlobalState } from "../../hooks/globalState";

import api from '../../services/api';

import "./style.css";

export default function About() {

    const navigate = useNavigate();

    const { setShowExplore } = useGlobalState();

    const [ content, setContent ] = useState("");

    useEffect(() => {
        // api.get('/about').then(res => console.log(res.data.description))
    })

    return (
        <div id='terms'>
            <div className='header'>
                <div className='icon' onClick={() => navigate("/")}>
                    <object data={logo} type="image/svg+xml" />
                </div>
                <div className='explore' onClick={() => setShowExplore(true)}><DehazeTwoTone className='icon-explore' /></div>
            </div>
            <div className='content'>
                <h1>Sobre nós</h1>
                <img src={imageAbout} alt="Sobre nós" className='about' />
                <p> A Mille Cuca é fundada pela Mille e seu irmão Adoan. Em 2016, com apenas 19 anos, ela começou a fazer cookies na cozinha da casa dos seus pais. Hoje a marca tem 3 lojas próprias, duas em Americana e outra no Cambuí em Campinas. Os cookies da Mille levam a receita original que ela criou lá em 2016, e hoje tem os mais diversos recheios e sabores, tudo feito artesanalmente na nossa cozinha. Uma experiência que a sua alegria pede 🧡 </p>
            </div>
        </div>
    );
}
