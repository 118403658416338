import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Brand from "../../components/Brand";
import Button from "../../components/Button";

import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: "16pt",
    position: "relative",
    backgroundColor: "#F4F4F4",
    border: "1pt solid #9A9A9A",
    fontSize: 16,
    padding: "10px 26px ",
    fontFamily: "Lexend",
    "&:focus": {
      borderRadius: "16pt",
    },
  },
}));

export default function Order() {
  const navigate = useNavigate();

  const {
    cart,
    updateCart,
    isMobile,
    setForceLogin,
    user,
    forceLogin,
    loadedData,
    banner,
    stores,
  } = useGlobalState();

  const [availableStores, setAvailableStores] = useState(stores);

  useEffect(() => {
    if ((!user || !user.id) && forceLogin && loadedData) {
      setForceLogin(false);
      navigate("/login");
    }
  }, [loadedData]);

  useEffect(() => {
    setAvailableStores(
      stores.filter((s) =>
        cart.delivery === "delivery"
          ? s.acceptDelivery == 1
          : s.acceptWithdrawl == 1
      )
    );
  }, [cart.delivery]);

  return (
    <div
      id="order"
      className="imageBg"
      style={{
        backgroundImage: `url(${isMobile ? banner.url_mobile : banner.url})`,
      }}
    >
      <div className="welcome-order">
        <Brand back={true} />
      </div>
      <div className="content-area">
        {!cart.delivery && (
          <>
            <div className="header">
              <h3>Vamos pedir!</h3>
            </div>
            <div className="infos">
              <p>
                Gostaria de receber seu pedido
                <br /> em sua casa ou retirar em loja?
              </p>
            </div>
          </>
        )}
        {cart.delivery === "delivery" && availableStores.length > 0 && (
          <div className="header">
            <h3>
              Selecione a loja que irá <br /> enviar seu pedido
            </h3>
          </div>
        )}
        {cart.delivery === "withdrawal" && availableStores.length > 0 && (
          <div className="header">
            <h3>
              Selecione uma das <br /> lojas disponíveis
            </h3>
          </div>
        )}
        {availableStores.length == 0 &&
          (cart.delivery === "delivery" || cart.delivery === "withdrawal") && (
            <div className="header">
              <h3>
                Esta modalidade de entrega <br /> não está disponível :(
              </h3>
            </div>
          )}
        <Button
          kind={cart.delivery === "delivery" ? "primary" : "selected"}
          label="Entrega"
          onClick={() => {
            updateCart("delivery", "delivery");
            if (
              cart.store &&
              !stores.find((s) => s.id === cart.store && s.acceptDelivery == 1)
            )
              updateCart("store", null);
          }}
        />
        <Button
          kind={cart.delivery === "withdrawal" ? "secondary" : "selected"}
          label="Retirada"
          onClick={() => {
            updateCart("delivery", "withdrawal");
            if (
              cart.store &&
              !stores.find((s) => s.id === cart.store && s.acceptWithdrawl == 1)
            )
              updateCart("store", null);
          }}
        />
        {cart.delivery && availableStores.length > 0 && (
          <NativeSelect
            defaultValue={0}
            value={cart.store}
            onChange={(e) => {
              updateCart("store", e.target.value);
            }}
            input={<BootstrapInput />}
          >
            <option value={0} disabled>
              Selecione a loja
            </option>
            {availableStores.map((store) => (
              <option value={store.id}>{store.name}</option>
            ))}
          </NativeSelect>
        )}
        {cart.store && cart.delivery && (
          <Button
            kind="primary"
            label="Continuar"
            onClick={() => navigate("/cardapio")}
          />
        )}
      </div>
    </div>
  );
}
