import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";

import OrderVisualization from "../../components/OrderVisualization";
import { ArrowBack, DehazeTwoTone } from "@mui/icons-material";
import { useGlobalState } from "../../hooks/globalState";
import { orderStatus } from "../../helpers/vars";

import moment from "moment";
import api from "../../services/api";

import "./style.css";

export default function ProfileOrders() {
  const navigate = useNavigate();
  const { user, loadedData, products, isLoggedIn } = useGlobalState();

  const { setShowExplore } = useGlobalState();

  const [orders, setOrders] = useState([]);
  const activeOrders = orders
    .filter(
      (order) =>
        moment(order.date, "DD/MM/YYYY").isSameOrBefore(
          moment().add(7, "days")
        ) && moment(order.date, "DD/MM/YYYY").isSameOrAfter(moment())
    )
    .reverse();
  const scheduledOrders = orders
    .filter((order) =>
      moment(order.date, "DD/MM/YYYY").isSameOrAfter(moment().add(7, "days"))
    )
    .reverse();
  const historyOrders = orders
    .filter((order) => moment(order.date, "DD/MM/YYYY").isBefore(moment()))
    .reverse();

  useEffect(() => {
    if ((!user || !user.id || !isLoggedIn) && loadedData) navigate("/login");
  }, [loadedData]);

  useEffect(() => {
    if (user && products.length > 0)
      api.get(`/order/${user.id}`).then(({ data }) => {
        data.forEach((order) =>
          order.boxes.forEach((cart) => {
            cart.itens = getCartItens(cart);
            cart.date = moment(order.date, "YYYY-MM-DD").format("DD/MM/YYYY");
            cart.status = orderStatus[order.status];
          })
        );
        setOrders(data);
      });
  }, [user, products]);

  function getCartItens(cart) {
    let cartItens = [];
    for (let item of cart.items) {
      let product = {
        ...products.find((product) => product.id === item.product_id),
      };
      product.qtt = item.qtd;
      cartItens.push(product);
    }
    return cartItens;
  }

  return (
    <div id="orders">
      <div className="header">
        <Avatar onClick={() => navigate(-1)}>
          <ArrowBack className="icon" />
        </Avatar>
        <div className="explore" onClick={() => setShowExplore(true)}>
          <DehazeTwoTone className="icon-explore" />
        </div>
      </div>
      <div className="orders">
        <div className="order">
          <h4>Meus pedidos ativos</h4>
          {activeOrders.map((order) => (
            <OrderVisualization
              order={order}
              active={false}
              isUnmutable
              isExpandable
            />
          ))}
        </div>
        <div className="order">
          <h4>Meus pedidos agendados</h4>
          {scheduledOrders.map((order) => (
            <OrderVisualization
              order={order}
              active={false}
              isUnmutable
              isExpandable
            />
          ))}
        </div>
        <div className="order">
          <h4>Meus pedidos finalizados</h4>
          {historyOrders.map((order) => (
            <OrderVisualization
              order={order}
              active={false}
              isUnmutable
              isExpandable
            />
          ))}
        </div>
      </div>
    </div>
  );
}
