import React, { useState, useEffect } from "react";

import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import $ from "jquery";

import "./style.css";

export default function Post({ index, maxIndex, post }) {
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    if (readMore) $(".post-content").children(":gt(2)").show();
    else $(".post-content").children(":gt(2)").hide();
  }, [readMore]);
  return (
    <div className="post" id={`post-${index}`}>
      <p>
        {new Date(post.created_at).toLocaleDateString("pt", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })}
      </p>
      <h1>{post.title}</h1>
      <h2>{post.subtitle}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: post.content.replace(
            /(\.\.\/)*uploads\/tmp/g,
            `${process.env.REACT_APP_URL}/uploads/tmp`
          ),
        }}
        className="post-content"
      />
      {readMore ? (
        <p className="read-more" onClick={() => setReadMore(false)}>
          Leia menos <ArrowUpwardRounded />
        </p>
      ) : (
        <p className="read-more" onClick={() => setReadMore(true)}>
          Leia mais <ArrowDownwardRounded />
        </p>
      )}
      <div style={{ height: index === maxIndex ? "10vh" : "5vh" }} />
      <div className="ref" id={`ref-${index}`} />
    </div>
  );
}
