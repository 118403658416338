import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import DeliveryAdressess from "../../components/DeliveryAdressess";
import AddAddressOption from '../../components/AddAddressOption';
import AddressForm from '../../forms/AddressForm';

import { ArrowBack } from '@mui/icons-material'
import Avatar from '@mui/material/Avatar';

import { formatNumber, getCartTotal } from '../../helpers/cart';
import { useGlobalState } from "../../hooks/globalState";
import { updateUserInfo } from '../../helpers/userManagement';
import { showNotification } from '../../helpers/notification';

import "./style.css";

export default function OrderAddress() {

    const navigate = useNavigate();

    const { cart, boxes, user, setForceLogin, updateCart, setUser, loadedData } = useGlobalState();
    const [ address, setAddress ] = useState({});
    const [ newAddress, setNewAddress ] = useState(true)
    const total = getCartTotal(boxes).toFixed(2);

    const deliveryTax = cart.zipSet ? cart.zipSet.price.toFixed(2) : "0,00";
    const totalWTax = (parseFloat(total) + parseFloat(deliveryTax)).toFixed(2);

    useEffect(() => {
        if ((!user || !user.id) && loadedData) {
            setForceLogin(true);
            navigate('/login');
        }
        if ((!cart || !cart.delivery) && loadedData) navigate('/novo-pedido');
    }, [ loadedData ])

    function handleNext() {
        if (!address.cep || !cart.zipSet) showNotification("Dados incompletos", "Por favor, complete seu endereço corretamente.")
        else {
            navigate("/checkout/pagamento")
            updateCart("address", address);
            updateCart("cep", address.cep);
            if (newAddress) {
                let addresses = user.addresses ? JSON.parse(user.addresses) : []
                addresses.push(address);
                addresses = JSON.stringify(addresses);
                updateUserInfo(user.id, { addresses })
                setUser((oldUser) => {
                    oldUser.addresses = addresses;
                    return oldUser
                })
            }
        }
    }

    return (
        <div id="checkout" className='address'>
            <div className="header">
                <Avatar onClick={() => navigate(-1)}>
                    <ArrowBack className='icon' />
                </Avatar>
            </div>
            <div className='content'>
                {user && user.addresses && user.addresses.length > 1 ? <>
                    <DeliveryAdressess setNewAddress={setNewAddress} address={address} setAddress={setAddress} />
                    <AddAddressOption newAddress={newAddress} setNewAddress={setNewAddress} address={address} setAddress={setAddress} />
                </> : <AddressForm address={address} setAddress={setAddress} />}
            </div>
            <div className='bottom'>
                {cart.delivery === "delivery" && <div className="desc-price">
                    <div>
                        <p>Valor da entrega</p>
                        <p className="number">{formatNumber(deliveryTax)}</p>
                    </div>
                </div>}
                <div className="total">
                    <p>Valor total:</p>
                    <p className="number">{formatNumber(totalWTax)}</p>
                </div>
                <Button
                    kind={"primary"}
                    label="Selecionar forma de pagamento"
                    onClick={handleNext}
                />
            </div>
        </div>
    );
}
