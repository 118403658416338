import React from "react";

import { AddCircleRounded } from '@mui/icons-material';
import AddressForm from '../../forms/AddressForm';

export default function AddAddressOption({ newAddress, setNewAddress, address, setAddress }) {

    return (
        <>
            <div
                className="add-more-address option"
                onClick={() => {
                    setNewAddress(oldAddress => !oldAddress);
                    setAddress({})
                }}
            >
                <p>Adicione um novo endereço</p>
                <AddCircleRounded />
            </div>
            {newAddress && <AddressForm address={address} setAddress={setAddress} />}
        </>
    );
}
