import axios from "axios";
import api from "../services/api";

export async function getCityFromCep(cep) {
  const response = await axios.get(
    `https://brasilapi.com.br/api/cep/v1/${cep}`
  );
  return response.data.city;
}

export async function getAddressFromCep(cep) {
  const response = await axios.get(
    `https://brasilapi.com.br/api/cep/v1/${cep}`
  );
  return response.data;
}

export async function calculateDeliveryTax(from, to, store) {
  const { data } = await api
    .post("shipping", { from, to, store })
    .catch((e) => console.log(e));
  return data.zip_set;
}
