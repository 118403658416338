import React from "react";

import { AddCircleRounded } from '@mui/icons-material';
import CardForm from '../../forms/CardForm';

export default function AddCardOption({ newCard, saveCard, setSaveCard, setNewCard, paymentMethod, setPaymentMethod, profile }) {

    return (
        <>
            <div
                className={`add-card option ${newCard ? 'selected' : ""}`}
                onClick={() => {
                    setNewCard(true);
                    setPaymentMethod({})
                }}
            >
                <p>Adicionar forma de pagamento</p>
                <AddCircleRounded />
            </div>
            {newCard && <CardForm profile paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} setSaveCard={setSaveCard} saveCard={saveCard} />}
        </>
    );
}
