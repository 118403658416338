import { updateUserInfo } from "./userManagement";

export function handleFavorite(user, product, favorite, setUser) {
  const userTmp = structuredClone(user);
  if (userTmp) {
    const currFavorites = userTmp.favorites;
    if (favorite) {
      if (currFavorites)
        userTmp.favorites = [...currFavorites.split(","), product].join(",");
      else userTmp.favorites = `${product}`;
    } else
      userTmp.favorites = currFavorites
        .split(",")
        .filter((favorite) => favorite != product)
        .join(",");
    setUser(userTmp);
    updateUserInfo(user.id, { favorites: userTmp.favorites });
  }
}
