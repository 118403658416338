import moment from "moment";
import { getCupom } from "./cupom";
import { showNotification } from "./notification";
import {
  applyGiftDiscount,
  applyFreeItemDiscount,
  applyPercentageCupom,
} from "./cupom";

export function addItensToCart(products, cart, updateCart) {
  updateCart("id", cart.id);
  updateCart("name", cart.name);

  let itens = [];
  if (cart && cart.itens) itens = itens.concat(cart.itens);

  for (let product of products) {
    const existantIndex = itens.findIndex(
      (existant) => existant.id == product.id
    );
    const qtt = product.qtt ? product.qtt : 1;
    if (existantIndex === -1) {
      product.qtt = qtt;
      itens.push(product);
    } else itens[existantIndex].qtt += qtt;
  }
  updateCart("itens", itens);
}

export function addItemToCart(product, cart, updateCart, qtt = 1) {
  updateCart("id", cart.id);
  updateCart("name", cart.name);

  let itens = [];
  if (cart && cart.itens) itens = itens.concat(cart.itens);

  if (qtt === 0) {
    itens.filter((p) => p.id !== product.id);
  } else {
    const existantIndex = itens.findIndex(
      (existant) => existant.id == product.id
    );
    if (existantIndex === -1) {
      product.qtt = qtt;
      itens.push(product);
    } else if (qtt > 1) itens[existantIndex].qtt = qtt;
    else itens[existantIndex].qtt += qtt;
  }
  updateCart("itens", itens);
}

export function removeItemFromCart(product, cart, updateCart) {
  updateCart("id", cart.id);
  updateCart("name", cart.name);

  let itens = [...cart.itens];
  itens = itens
    .map((existant) => {
      if (existant.id === product.id) existant.qtt -= 1;
      if (existant.qtt > 0) return existant;
    })
    .filter((item) => item);
  updateCart("itens", itens);
}

export function formatNumber(num) {
  return num.toString().replace(".", ",");
}

export function getCartTotal(boxes) {
  const total = boxes.reduce(
    (previousCartTotal, cart) =>
      cart.itens.reduce(
        (previousValue, currentValue) =>
          previousValue +
          parseFloat(currentValue.price.replace(",", ".")) * currentValue.qtt,
        0
      ) + previousCartTotal,
    0
  );
  const gifts = boxes.filter((box) => box.isGift);
  return total + gifts.length * 5;
}

export function getCartQtt(cart) {
  return cart.itens.reduce(
    (previousValue, currentValue) => previousValue + currentValue.qtt,
    0
  );
}

export function removeProducts(boxes, products, setBoxes) {
  let tmp = [...boxes];
  tmp.map((cart) => {
    cart.itens = cart.itens.filter((item) => !products.includes(item.id));
  });
  setBoxes(tmp);
}

export function getBoxesProducts(boxes) {
  return boxes.reduce(
    (previousCartTotal, cart) => [
      ...previousCartTotal,
      ...cart.itens.reduce(
        (previousValue, currentValue) => [...previousValue, currentValue.id],
        []
      ),
    ],
    []
  );
}

export function applyDiscount(boxes, cupom, products, cart, updateCart) {
  let total = getCartTotal(boxes);
  let res = { total, discount: 0 };

  if (cupom.is_discount === 1) res = applyPercentageCupom(cupom, total);
  else if (cupom.is_discount === 0)
    res = applyFreeItemDiscount(boxes, cupom, total);
  else if (cupom.is_discount === 2) {
    const gift = products.find((product) => product.id === cupom.gift);
    if (gift) {
      let { discount } = applyGiftDiscount(gift, updateCart, cart);
      res.discount = discount;
    } else res = applyFreeItemDiscount(boxes, cupom, total);
  }
  updateCart("discount", res.discount);
  updateCart("total", res.total);
}

export async function checkCupom(
  code,
  cupom,
  setCupom,
  boxes,
  products,
  cart,
  updateCart,
  setLoading
) {
  setLoading(true);
  if (cupom) removeCupom(cupom, cart, products, updateCart);
  setCupom(null);
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
  const res = await getCupom(code, isStandalone);
  setLoading(false);
  if (res) {
    setCupom(res);
    applyDiscount(boxes, res, products, cart, updateCart);
    showNotification(
      "Cupom aplicado com sucesso!",
      "O desconto foi aplicado no carrinho."
    );
  } else
    showNotification(
      "Cupom inválido!",
      "O cupom inserido não é válido ou não existe."
    );
}

function removeCupom(cupom, cart, products, updateCart) {
  updateCart("total", null);
  updateCart("discount", null);
  if (cupom.is_discount === 2) {
    const gift = products.find((product) => product.id === cupom.gift);
    if (gift) removeItemFromCart(gift, cart, updateCart);
  }
}

export function getAllProducts(boxes) {
  const productsIds = new Set();
  for (let box of boxes) for (let item of box.itens) productsIds.add(item.id);
  return Array.from(productsIds);
}

export function arrangeCart(cart, boxes, user, cupom) {
  let resultingBoxes = [...boxes];
  resultingBoxes.forEach((box) => {
    box.total = getCartTotal([box]).toFixed(2).replace(".", ",");
  });

  let arrangedCart = {
    delivery: cart.delivery,
    date: cart.date,
    hour: cart.hour,
    comments: "",
    phone: user.mobile,
    phoneWork: user.mobile,
    customerId: user.id,
    store: cart.store,
    cupom: cupom ? cupom.code : "",
    discount: cart.discount,
    items: resultingBoxes,
  };

  if (cart.delivery === "delivery") {
    arrangedCart.address = {
      publicPlace: cart.address.publicPlace,
      neighborhood: cart.address.neighborhood,
      number: cart.address.number,
      city: cart.address.city,
      adjunct: cart.address.adjunct ? cart.address.adjunct : "",
      state: cart.address.state ? cart.address.state : "",
    };
    arrangedCart.zipSet = cart.zipSet;
    arrangedCart.cep = cart.address.cep.replace("-", "");
  }
  return arrangedCart;
}

export function disableHour(hour, date) {
  let now = moment();
  let deliveryDate = moment(`${date} ${hour}`, "YYYY-MM-DD HH:mm");
  deliveryDate = deliveryDate.add(17, "minutes");
  return now.isSameOrAfter(deliveryDate);
}
