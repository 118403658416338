import visa from 'payment-icons/min/flat/visa.svg';
import master from 'payment-icons/min/flat/mastercard.svg'
import amex from 'payment-icons/min/flat/amex.svg'
import elo from 'payment-icons/min/flat/elo.svg'
import jcb from 'payment-icons/min/flat/jcb.svg'
import diners from 'payment-icons/min/flat/diners.svg'
import discover from 'payment-icons/min/flat/discover.svg'
import defaultCard from 'payment-icons/min/flat/default.svg'

export const creditCardsTypes = {
    "visa": { enum: 1, svg: visa },
    "mastercard": { enum: 2, svg: master },
    "amex": { enum: 3, svg: amex },
    "elo": { enum: 4, svg: elo },
    "aura": { enum: 5, svg: defaultCard },
    "jcb": { enum: 6, svg: jcb },
    "diners": { enum: 7, svg: diners },
    "discover": { enum: 8, svg: discover },
    "hipercard": { enum: 9, svg: defaultCard },
    "enroute": { enum: 11, svg: defaultCard },
    "ticket": { enum: 12, svg: defaultCard },
    "sodexo": { enum: 13, svg: defaultCard },
    "vr": { enum: 14, svg: defaultCard },
    "alelo": { enum: 15, svg: defaultCard },
    "ourocard": { enum: 51, svg: defaultCard }
}

export const chargeStatus = {
    "Undefined": 0,
    "Authorized": 1,
    "PreAuthorized": 2,
    "Canceled": 4,
    "Partial": 5,
    "NotAuthorized": 6,
    "PendingCancel": 7,
    "Expired": 8,
    "Timeout": 9
}

export const orderStatus = [ 'Indefinido', 'Autorizado', 'Pré Autorizado', '', 'Cancelado', '', 'Não Autorizado', 'Cancelamento pendente', 'Cobrança expirada', 'Cobrança não processada devidamente' ];

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}