import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../Button";
import FinishOrder from "../../components/FinishOrder";

import { Close } from "@mui/icons-material";

import { removeItemFromCart } from "../../helpers/cart";
import { useGlobalState } from "../../hooks/globalState";
import moment from "moment";

import "./style.css";
import { showNotification } from "../../helpers/notification";
import { closedDays } from "../../helpers/stores";

const weekDays = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"];

export default function OrderDate({ setShowModal, product }) {
  const navigate = useNavigate();
  const { cart, updateCart, setSelectedDate, stores } = useGlobalState();

  const [availableDates, setAvailableDates] = useState([]);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const store = stores.find((s) => s.id == cart.store);

  useEffect(() => {
    getAvailableDates();
  }, []);

  function getWeekDay(date) {
    const givenDate = new Date(`${date}T12:00:00Z`);
    const today = new Date();
    if (givenDate.toLocaleDateString() === today.toLocaleDateString())
      return "Hoje";
    return weekDays[givenDate.getDay()];
  }

  function getMonthDay(date) {
    return date.split("-")[2];
  }

  function getAvailableDates() {
    let dates = [];
    let currDate = moment(product.available_from, "YYYY-MM-DD");
    let lastDate = moment(product.available_to, "YYYY-MM-DD");
    while (currDate.diff(lastDate) <= 0) {
      dates.push(currDate.format("YYYY-MM-DD"));
      currDate.add(1, "days");
    }
    dates = dates.filter((date) => !closedDays.some((d) => d === date));
    dates = dates.filter(
      (date) =>
        (date === moment().format("YYYY-MM-DD") ||
          moment(date, "YYYY-MM-DD").isSameOrAfter(moment())) &&
        store.hours[moment(date, "YYYY-MM-DD").day()]
    );
    updateCart("date", dates[0]);
    setAvailableDates(dates);
  }

  return (
    <div id="order-date-page">
      {!showFinishModal && (
        <div className="content">
          <div
            className="close"
            onClick={() => {
              removeItemFromCart(product, cart, updateCart);
              setShowModal(false);
              updateCart("date", null);
            }}
          >
            <Close />
          </div>
          <h4>Este produto está disponível entre os dias:</h4>
          <h3>
            {moment(product.available_from, "YYYY-MM-DD").format("DD/MM")} e{" "}
            {moment(product.available_to, "YYYY-MM-DD").format("DD/MM")}{" "}
          </h3>
          <h5>Selecione a data:</h5>
          <div className="days">
            {availableDates.map((date) => (
              <div
                className={cart.date === date ? "day selected" : "day"}
                onClick={() => {
                  updateCart("date", date);
                  setSelectedDate(date);
                }}
              >
                <p>{getWeekDay(date)}</p>
                <h5>{getMonthDay(date)}</h5>
              </div>
            ))}
          </div>
          <Button
            kind={"secondary"}
            label="Adicionar na caixinha"
            onClick={() => {
              if (cart.date) setShowModal(false);
              else
                showNotification(
                  "Faltam informações!",
                  "Por favor, escolha uma data."
                );
            }}
          />
          <h6>O cardápio será atualizado com a data selecionada</h6>
        </div>
      )}
      {showFinishModal && (
        <FinishOrder
          setShowFinishModal={setShowFinishModal}
          activeUser={false}
        />
      )}
    </div>
  );
}
