import React, { useEffect } from "react";

import GiftOption from '../GiftOption';
import AddItensOption from '../AddItensOption';

import { AddCircleRounded, RemoveCircleRounded } from '@mui/icons-material';

import { addItemToCart, removeItemFromCart, formatNumber } from "../../helpers/cart";
import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function BoxItens({ cart, className, isMultiple, isUnmutable }) {

  const {
    updateCart,
  } = useGlobalState();

  useEffect(() => {
    if (!cart.name) updateCart("name", "Nome da Caixinha");
  }, [])

  return (
    <>
      <div className={className}>
        <div className="box-itens">
          {cart.itens.map((item) => (
            <div className="item">
              <img src={`${item.main_image}/${item.image}`} alt="" />
              <div className="infos">
                <h3>{item.category_name}</h3>
                <h1>{item.name}</h1>
                <h2>{item.price}</h2>
              </div>
              <div className="add-remove">
                {isUnmutable ? null : <div
                  className="icon remove"
                  onClick={() => {
                    removeItemFromCart(item, cart, updateCart);
                  }}
                >
                  <RemoveCircleRounded />
                </div>}
                <h3>{item.qtt.toString().padStart(2, "0")}</h3>
                {isUnmutable ? null : <div
                  className="icon add"
                  onClick={() => {
                    addItemToCart(item, cart, updateCart);
                  }}
                >
                  <AddCircleRounded />
                </div>}
              </div>
            </div>
          ))}
        </div>
      </div>
      {!isMultiple && <AddItensOption cart={cart} />}
      {!isMultiple && <GiftOption cart={cart} />}
    </>
  );
}
