import React, { useState, useEffect } from "react";

import { Collapse } from "antd";

import { CheckCircle, Cancel, AddCircleRounded } from '@mui/icons-material';
import { GiftFilled } from "@ant-design/icons";

import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

const { Panel } = Collapse;

export default function GiftOption({ cart }) {

    const {
        updateCart,
    } = useGlobalState();

    const [ message, setMessage ] = useState("")
    const [ isGift, setIsGift ] = useState(false)
    const [ sendMessage, setSendMessage ] = useState(false)

    useEffect(() => {
        updateCart("isGift", isGift);
        updateCart("giftMessage", message)
    }, [ message, isGift ])

    return (
        <Collapse
            bordered={false}
            expandIcon={() => (
                <div
                    className="gift option"
                    onClick={() => {
                        updateCart("id", cart.id);
                        updateCart("itens", cart.itens);
                        updateCart("name", cart.name);
                        updateCart("message", cart.message);
                    }}
                >
                    <GiftFilled style={{ fontSize: "18pt" }} />
                    <p>{cart.message ? cart.message : "Essa caixinha será presente?"}</p>
                    {cart.message ? <CheckCircle /> : <AddCircleRounded />}
                    {cart.message && (
                        <div
                            id="cancelGiftMessage"
                            onClick={(e) => {
                                updateCart("id", cart.id);
                                updateCart("itens", cart.itens);
                                updateCart("name", cart.name);
                                updateCart("message", null);
                                setSendMessage(false)
                                setIsGift(false)
                                e.stopPropagation();
                            }}
                        >
                            <Cancel />
                        </div>
                    )}
                </div>
            )}
            className='gift-option'
        >
            <Panel
                key="1"
                className="collapse-content"
            >
                <div className='content'>
                    <div className='input is-gift'>
                        <div className='input'>
                            <input type='radio' aria-label='presentear com uma mensagem' checked={isGift}
                                onChange={(e) => setIsGift(e.target.checked)}
                            />
                            <p>Sim</p>
                        </div>
                        <div className='input'>
                            <input type='radio' aria-label='presentear com uma mensagem' checked={!isGift}
                                onChange={(e) => {
                                    setIsGift(!e.target.checked)
                                    setSendMessage(false)
                                }}
                            />
                            <p>Não</p>
                        </div>
                    </div>
                    {isGift && <>
                        {sendMessage && <>
                            <textarea rows={4} className='gift-message-input' placeholder='Insira seu texto aqui...' maxLength={280} value={message} onChange={(e) => setMessage(e.target.value)} />
                            <p className='word-count'>{message.length}/280</p>
                        </>}

                        <div className='input'>
                            <input type='radio' aria-label='presentear com uma mensagem' checked={sendMessage} onChange={(e) => setSendMessage(e.target.checked)} />
                            <p>Presentear com uma mensagem</p>
                        </div>
                        <div className='input'>
                            <input type='radio' aria-label='presentear com uma mensagem' checked={!sendMessage} onChange={(e) => {
                                setSendMessage(!e.target.checked)
                                setMessage("")
                            }} />
                            <p>Não quero mandar mensagem</p>
                        </div>
                    </>}
                </div>
            </Panel>
        </Collapse>

    );
}
