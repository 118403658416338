import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Post from "../../components/Post";
import Button from "../../components/Button";
import Avatar from '@mui/material/Avatar';

import logo from "../../images/icons/logo.svg";

import { Search, Upcoming, DehazeTwoTone } from '@mui/icons-material'
import { useGlobalState } from "../../hooks/globalState";

import Fuse from "fuse.js";

import "./style.css";

export default function News() {

    const navigate = useNavigate();

    const { setShowExplore, posts, filteredPosts, setFilteredPosts } = useGlobalState();

    const [ search, setSearch ] = useState('');

    const fuse = new Fuse(posts, { keys: [ "title", "subtitle", 'content' ] });

    useEffect(() => {
        if (search === "") setFilteredPosts(posts)
        else setFilteredPosts(
            fuse.search(search).map((result) => {
                return result.item;
            })
        );
    }, [ search ])


    return (
        <div id='news'>
            <div className='header'>
                <div className='icon' onClick={() => navigate("/")}>
                    <object data={logo} type="image/svg+xml" />
                </div>
                <div className='search'>
                    <div className='search-box'>
                        <div className="news-search">
                            <div className='search-avatar'>
                                <Search />
                            </div>
                            <input
                                id="searchBar"
                                placeholder="O que está procurando?"
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                        </div>
                    </div>
                </div>
                <div className='explore' onClick={() => setShowExplore(true)}><DehazeTwoTone className='icon-explore' /></div>
            </div>
            {filteredPosts.map((post, i) => (
                <Post
                    index={i}
                    maxIndex={posts.length - 1}
                    setHideSecondaryButton={() => { }}
                    length={posts}
                    post={post}
                />
            ))}
            {filteredPosts.length === 0 && <div className="empty common">
                <Upcoming />
                <p>Não encontramos nada :(</p>
            </div>}
            <div className='common bottom-fixed' >
                <Button
                    kind="primary"
                    label="Peça já!"
                    onClick={() => navigate("/cardapio")}
                />
            </div>
        </div>
    );
}
