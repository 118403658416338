import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ShoppingBag, AddCircleRounded, ArrowBack } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";

import Button from "../../components/Button";
import Box from "../../components/Box";
import BoxMultiple from "../../components/BoxMultiple";

import { useGlobalState } from "../../hooks/globalState";
import {
  formatNumber,
  getCartTotal,
  getBoxesProducts,
  removeProducts,
} from "../../helpers/cart";
import { showNotification } from "../../helpers/notification";
import { checkAvailability } from "../../helpers/data";

import { logToLoggly } from "../../helpers/log";

import "./style.css";

export default function Cart() {
  const navigate = useNavigate();

  const {
    cart,
    updateCart,
    boxes,
    loadedData,
    setLoading,
    setBoxes,
    setProducts,
    user,
  } = useGlobalState();

  const [total, setTotal] = useState("0,00");

  useEffect(() => {
    if ((!cart || !cart.delivery) && loadedData) navigate("/novo-pedido");
  }, [loadedData]);

  useEffect(() => {
    if (
      cart &&
      (!cart.itens ||
        boxes.some((cart) => !cart.itens || cart.itens.length <= 0))
    ) {
      updateCart("id", 0);
      updateCart("name", null);
      showNotification("Caixinhas vazias!", "Começando uma nova caixinha...");
      navigate("/cardapio");
    }
  }, [cart]);

  useEffect(() => {
    setTotal(getCartTotal(boxes).toFixed(2));
    if (boxes.length === 0) navigate("/cardapio");
  }, [boxes]);

  async function checkout() {
    try {
      setLoading(true);
      let products = getBoxesProducts(boxes);
      let availability = {};
      if (cart.isOrder) availability.updated = false;
      else availability = await checkAvailability(products, cart.store);
      if (availability.updated) {
        removeProducts(boxes, availability.products, setBoxes);
        setProducts((oldProducts) =>
          oldProducts.filter(
            (product) => !availability.products.includes(product.id)
          )
        );
        showNotification(
          `Ops, alguns produtos não estão mais disponíveis na loja ${
            stores.find((store) => store.id == cart.store)?.name
          } :(`,
          "Revise suas caixinhas para concluir seu pedido."
        );
      } else {
        navigate("/checkout/verify");
      }
    } catch (error) {
      logToLoggly("erro ao iniciar checkout", error);
      showNotification(
        "Ops, ocorreu um erro :(",
        "Por favor, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <div id="cart">
      <div className="header">
        <Avatar onClick={() => navigate(-1)}>
          <ArrowBack className="icon" />
        </Avatar>
      </div>
      <div className="welcome-msg">
        {boxes.length > 1
          ? "Vamos revisar suas caixinhas?"
          : "Vamos revisar sua caixinha?"}
      </div>
      {boxes.map((cart) =>
        boxes.length > 1 ? <BoxMultiple cart={cart} /> : <Box cart={cart} />
      )}
      <div
        className="new-box option"
        onClick={() => {
          const shallowClone = [...boxes];
          const ids = shallowClone.map((cart) => cart.id);
          updateCart("id", Math.max(...ids) + 1);
          updateCart("itens", []);
          updateCart("name", null);
          updateCart("message", null);
          showNotification(
            "Criando nova caixinha!",
            "Começando uma nova caixinha..."
          );
          navigate("/cardapio");
        }}
      >
        <ShoppingBag />
        <p>Deseja criar uma nova caixinha?</p>
        <AddCircleRounded />
      </div>
      <div className="total">
        <p>Valor total:</p>
        <p className="number">{formatNumber(total)}</p>
      </div>
      <div id="buttonFooter">
        <Button kind={"primary"} label="Fechar caixinha" onClick={checkout} />
      </div>
    </div>
  );
}
