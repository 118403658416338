import React from "react";
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';

import { ArrowBack, DehazeTwoTone } from '@mui/icons-material'
import logo from "../../images/icons/logo-branco.svg";

import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function Brand({ back }) {
  const navigate = useNavigate();
  const { setShowExplore } = useGlobalState();

  return <div id="explore-btn">
    {back ? <Avatar onClick={() => navigate(-1)}>
      <ArrowBack className='icon' />
    </Avatar> : <div onClick={() => navigate("/")}><object data={logo} type="image/svg+xml" /></div>}
    <p onClick={() => setShowExplore(true)}><DehazeTwoTone /></p>
  </div>;
}
