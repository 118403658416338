import React from "react";
import Brand from "../../components/Brand";
import Button from "../../components/Button";

import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function Closed() {
  const { setShowExplore, isMobile, banner } = useGlobalState();
  return (
    <div id="closed" className='imageBg' style={{ backgroundImage: `url(${isMobile ? banner.url_mobile : banner.url})` }}>
      <div className="explore" />
      <div className="brand-closed common">
        <Brand />
        <div className="content-area">
          <div className="header">
            <h3>Poxa, no momento estamos fechados 😢</h3>
          </div>
          <div className="infos">
            <h3>
              Maaas, ainda é possível agendar seu pedido para receber assim que
              abrirmos!
            </h3>
            <h3>
              Não possui cadastro? Ganhe 10% no seu primeiro pedido cadastrando
              agora!
            </h3>
          </div>
          <Button kind={"primary"} label="Cadastre-se + 10% off" />
          <Button kind={"secondary"} label="Tenho cadastro e quero agendar" />
        </div>
      </div>
    </div>
  );
}
