import React, { useEffect, useState } from "react";

import Button from "../Button";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

import { Close } from "@mui/icons-material";
import { useGlobalState } from "../../hooks/globalState";

import moment from "moment";

import "./style.css";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: "16pt",
    position: "relative",
    backgroundColor: "#9a9a9a",
    border: "none",
    fontSize: 16,
    padding: "10px 26px ",
    fontFamily: "Lexend",
    "&:focus": {
      borderRadius: "16pt",
      backgroundColor: "#9a9a9a",
    },
  },
}));

export default function CityModal({ setOpen }) {
  const { cart, updateCart, stores } = useGlobalState();
  const [availableStores, setAvailableStores] = useState(stores);

  function handleClose() {
    if (!cart.store) updateCart("delivery", null);
    setOpen(false);
  }

  useEffect(() => {
    setAvailableStores(
      stores.filter((s) =>
        cart.delivery === "delivery"
          ? s.acceptDelivery == 1
          : s.acceptWithdrawl == 1
      )
    );
  }, [cart.delivery]);

  return (
    <div id="select-city">
      <div className="content">
        <div className="close" onClick={handleClose}>
          <Close />
        </div>
        <h3>
          Antes de adicionar produtos à sua caixinha, precisamos saber algumas
          coisas sobre o seu pedido:
        </h3>
        <div className="order-type">
          <Button
            kind={
              cart.isOrder !== null && cart.isOrder === false
                ? "primary"
                : "selected"
            }
            label="Quero fazer um pedido para hoje"
            onClick={() => {
              updateCart("date", moment().format("YYYY-MM-DD"));
              updateCart("hour", null);
              updateCart("isOrder", false);
            }}
          />
          <Button
            kind={
              cart.isOrder !== null && cart.isOrder === true
                ? "secondary"
                : "selected"
            }
            label="Quero fazer uma encomenda"
            onClick={() => {
              updateCart("date", null);
              updateCart("hour", null);
              updateCart("isOrder", true);
            }}
          />
        </div>
        {"isOrder" in cart && (
          <div className="order-type">
            <Button
              kind={cart.delivery === "delivery" ? "primary" : "selected"}
              label="Entrega"
              onClick={() => {
                updateCart("delivery", "delivery");
                if (
                  cart.store &&
                  !stores.find(
                    (s) => s.id === cart.store && s.acceptDelivery == 1
                  )
                )
                  updateCart("store", null);
              }}
            />
            <Button
              kind={cart.delivery === "withdrawal" ? "secondary" : "selected"}
              label="Retirada"
              onClick={() => {
                updateCart("delivery", "withdrawal");
                if (
                  cart.store &&
                  !stores.find(
                    (s) => s.id === cart.store && s.acceptWithdrawl == 1
                  )
                )
                  updateCart("store", null);
              }}
            />
          </div>
        )}
        {cart.delivery && availableStores.length > 0 && (
          <NativeSelect
            defaultValue={0}
            value={cart.store}
            onChange={(e) => {
              updateCart("store", e.target.value);
            }}
            input={<BootstrapInput />}
          >
            <option value={0} disabled>
              Selecione a loja
            </option>
            {availableStores.map((store) => (
              <option value={store.id}>{store.name}</option>
            ))}
          </NativeSelect>
        )}
        {cart.delivery && availableStores.length < 0 && (
          <h4>Esta modalidade de entrega não está disponível :(</h4>
        )}
        {cart.store && (
          <Button
            kind="primary"
            label="Continuar"
            onClick={() => setOpen(false)}
          />
        )}
      </div>
    </div>
  );
}
