import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import PhoneModal from "../../components/PhoneModal";

import Button from "../../components/Button";
import Calendar from "react-calendar";
import InputBase from "@mui/material/InputBase";
import NativeSelect from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";

import { useGlobalState } from "../../hooks/globalState";
import { showNotification } from "../../helpers/notification";
import { disableHour } from "../../helpers/cart";
import { closedDays } from "../../helpers/stores";

import moment from "moment";

import "react-calendar/dist/Calendar.css";
import "./style.css";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: "16pt",
    position: "relative",
    backgroundColor: "#F4F4F4",
    border: "1pt solid #9A9A9A",
    fontSize: 16,
    padding: "10px 26px ",
    fontFamily: "Lexend",
    "&:focus": {
      borderRadius: "16pt",
    },
  },
}));

export default function OrderDate() {
  const navigate = useNavigate();

  const { cart, updateCart, boxes, loadedData, user, setForceLogin, stores } =
    useGlobalState();

  const [store, setStore] = useState(stores.find((s) => s.id == cart.store));
  const [showPhoneModal, setShowPhoneModal] = useState(false);

  useEffect(() => {
    if ((!user || !user.id) && loadedData) {
      setForceLogin(true);
      navigate("/login");
    }
    if ((!cart || !cart.delivery) && loadedData) navigate("/novo-pedido");
    if (loadedData) setStore(stores.find((s) => s.id == cart.store));
  }, [loadedData]);

  function verifyCartItens(date) {
    for (let box of boxes) {
      for (let product of box.itens)
        if (
          product.isLimited === 1 &&
          !moment(date, "YYYY-MM-DD").isBetween(
            moment(product.available_from, "YYYY-MM-DD"),
            moment(product.available_to, "YYYY-MM-DD"),
            undefined,
            "[]"
          )
        )
          return true;
    }
    return false;
  }

  function disableDate({ date }) {
    if (
      cart.isOrder &&
      moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
    )
      return true;
    if (closedDays.some((d) => d === moment(date).format("YYYY-MM-DD")))
      return true;

    date = moment(date, "YYYY-MM-DD");

    const functioningHours = store.hours[date.day()];

    if (!functioningHours || verifyCartItens(date)) return true;

    if (date.isBefore(new Date(), "day")) return true;
    if (date.isSame(new Date(), "day")) {
      const acceptTo = functioningHours.orders_to;
      return moment().isSameOrAfter(moment(acceptTo, "HH:mm"), "hour");
    }
  }

  function handleNext() {
    if (cart.date && disableDate({ date: cart.date })) {
      showNotification("A data escolhida não está disponível.", "");
    } else if (cart.date) {
      if (cart.delivery === "delivery") navigate("/checkout/entrega");
      else navigate("/checkout/pagamento");
    } else
      showNotification(
        "Os campos não foram preenchidos corretamente.",
        "Por favor, tente novamente."
      );
  }

  function getAvailableHours() {
    const date = moment(cart.date, "YYYY-MM-DD");
    const functioningHours = store.hours[date.day()];

    let open = moment(functioningHours.open_time, "HH:mm");
    const acceptTo = moment(functioningHours.orders_to, "HH:mm");
    const availableHours = [];

    while (open.isSameOrBefore(acceptTo)) {
      availableHours.push({
        hour: open.format("HH:mm"),
        label: `${open.format("HH:mm")} às ${moment(open)
          .add(1, "hour")
          .format("HH:mm")}`,
      });
      open = moment(open).add(1, "hour");
    }

    return availableHours;
  }

  return (
    <div id="order-date">
      <div className="header">
        <Avatar
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBack className="icon" />
        </Avatar>
      </div>
      {cart.isOrder === true && (
        <>
          <div className="welcome-msg">Selecione a data</div>
          {store && (
            <Calendar
              onChange={(e) => {
                updateCart("date", moment(e).format("YYYY-MM-DD"));
              }}
              maxDate={moment().add(1, "month").toDate()}
              minDate={new Date()}
              value={
                cart.date ? moment(cart.date, "YYYY-MM-DD").toDate() : null
              }
              tileDisabled={disableDate}
              locale="pt-BR"
            />
          )}
        </>
      )}
      {cart.date && <div className="welcome-msg">Selecione o horário</div>}
      <div className="buttonFooter">
        {cart.date && (
          <NativeSelect
            className="hourSelect"
            defaultValue={0}
            value={cart.hour}
            onChange={(e) => updateCart("hour", e.target.value)}
            input={<BootstrapInput />}
          >
            <option value={0} disabled>
              Selecione o horário
            </option>
            {store &&
              getAvailableHours().map((h) => (
                <option
                  value={h.label}
                  disabled={disableHour(h.hour, cart.date)}
                >
                  {h.label}
                </option>
              ))}
          </NativeSelect>
        )}
        {cart.date && cart.hour && (
          <Button
            kind={"primary"}
            label={
              cart.delivery === "delivery"
                ? "Selecionar endereço"
                : "Selecionar forma de pagamento"
            }
            onClick={() => {
              if (user.mobile) handleNext();
              else setShowPhoneModal(true);
            }}
          />
        )}
      </div>
      {showPhoneModal && (
        <PhoneModal
          handleNext={handleNext}
          setShowPhoneModal={setShowPhoneModal}
        />
      )}
    </div>
  );
}
