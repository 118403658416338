import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import PaymentMethods from "../../components/PaymentMethods";
import AddCardOption from "../../components/AddCardOption";
import Avatar from "@mui/material/Avatar";

import { CheckCircleOutline, ArrowBack } from "@mui/icons-material";
import {
  formatNumber,
  getCartTotal,
  checkCupom,
  applyDiscount,
  arrangeCart,
  removeProducts,
  getBoxesProducts,
} from "../../helpers/cart";
import {
  getTokens,
  createCharge,
  createPixCharge,
  createCard,
} from "../../helpers/safraPay/safraPay";
import { useGlobalState } from "../../hooks/globalState";
import { showNotification } from "../../helpers/notification";
import { chargeStatus } from "../../helpers/vars";
import { checkAvailability } from "../../helpers/data";
import CartPreview from "../../components/CartPreview";
import { logToLoggly } from "../../helpers/log";

import api from "../../services/api";

import "./style.css";

export default function OrderPayment() {
  const navigate = useNavigate();

  const {
    cart,
    boxes,
    user,
    loadedData,
    setCart,
    setBoxes,
    setLoading,
    products,
    updateCart,
    setProducts,
    cupom,
    setCupom,
  } = useGlobalState();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [newCard, setNewCard] = useState(false);
  const [saveCard, setSaveCard] = useState(false);

  const [code, setCode] = useState("");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (cart.itens.length === 0) navigate("/");
    if (loadedData) setTotal((cart.total ?? getCartTotal(boxes)).toFixed(2));
  }, [cart]);

  const deliveryTax = cart.zipSet ? cart.zipSet.price.toFixed(2) : "0,00";
  const totalWTax = (parseFloat(total) + parseFloat(deliveryTax)).toFixed(2);

  async function handleCheckout() {
    if (!paymentMethod)
      showNotification("Por favor, selecione a forma de pagamento", "");
    else {
      try {
        setLoading(true);

        let products = getBoxesProducts(boxes);
        let availability = {};
        if (cart.isOrder) availability.updated = false;
        else availability = await checkAvailability(products, cart.store);
        if (availability.updated) {
          removeProducts(boxes, availability.products, setBoxes);
          setProducts((oldProducts) =>
            oldProducts.filter(
              (product) => !availability.products.includes(product.id)
            )
          );
          showNotification(
            "Ops, alguns produtos não estão mais disponíveis :(",
            "Revise suas caixinhas, e se ainda quiser continuar, conclua seu pedido."
          );
          navigate("/carrinho");
        } else {
          await getTokens();
          let amount = parseInt(
            totalWTax.toString().replace(/\./g, "").replace(/,/g, "")
          );
          let resultingCart = arrangeCart(cart, boxes, user, cupom);

          api
            .post(`order`, {
              cart: resultingCart,
              paymentMethod: "card",
              // safraId: res.charge.id,
              // safraStatus: chargeStatus[res.charge.chargeStatus],
              // pixBase64: res.charge.transactions[0].qrCodeBase64,
              // pixCode: res.charge.transactions[0].qrCode,
            })
            .then(async ({ data }) => {
              let res;
              if (paymentMethod === "pix")
                res = await createPixCharge(
                  user.safraId,
                  amount,
                  data.order.id
                );
              else
                res = await createCharge(
                  user.safraId,
                  paymentMethod,
                  amount,
                  data.order.id
                );

              if (
                paymentMethod === "pix" ||
                res.charge.transactions[0].isApproved
              ) {
                setCart({
                  id: 0,
                  name: null,
                  message: null,
                  itens: [],
                });
                setBoxes([]);

                navigate(`/checkout/success`, {
                  state: {
                    isPix: paymentMethod === "pix",
                    base64: res.charge.transactions[0].qrCodeBase64,
                    code: res.charge.transactions[0].qrCode,
                    fidelity_count: data.fidelity_count,
                    orderId: data.order.id,
                  },
                });
              } else {
                console.log("erro ao registrar pagamento", res);
                logToLoggly("erro ao registrar pagamento", res.charge);
                navigate(`/checkout/failure`, {
                  state: { refusedPaymentMethod: paymentMethod },
                });
              }
            })
            .catch((e) => {
              console.log("erro ao registrar pedido", e);
              logToLoggly("erro ao registrar pedido", e);
            });
        }
      } catch (error) {
        logToLoggly("erro ao processar pagamento", error);
        console.log("ERRO", error);
        showNotification(
          "Ocorreu um erro ao processar seu pedido.",
          "Por favor, tente novamente."
        );
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <div id="checkout" className="address payment">
      <div className="header">
        <Avatar onClick={() => navigate(-1)}>
          <ArrowBack className="icon" />
        </Avatar>
      </div>
      <div className="content">
        <CartPreview />
        <PaymentMethods
          setNewCard={setNewCard}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
        />
        {/* <AddCardOption
          newCard={newCard}
          saveCard={saveCard}
          setNewCard={setNewCard}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          setSaveCard={setSaveCard}
        /> */}
        <div className="cupom-title">
          <h1>Tem um cupom de desconto?</h1>
        </div>
        <div className="cupom-box">
          <input
            id="searchBar"
            placeholder="Insira um cupom de desconto"
            onChange={(e) => setCode(e.target.value)}
            value={code}
            onKeyDown={(e) => {
              if (e.key === "Enter")
                checkCupom(
                  code,
                  cupom,
                  setCupom,
                  boxes,
                  products,
                  cart,
                  updateCart,
                  setLoading
                );
            }}
          />
          <Button
            className="check-button"
            label="Validar"
            onClick={() =>
              checkCupom(
                code,
                cupom,
                setCupom,
                boxes,
                products,
                cart,
                updateCart,
                setLoading
              )
            }
          />
        </div>
      </div>
      <div className="bottom">
        <div className="desc-price">
          {cart.delivery === "delivery" && (
            <div>
              <p>Subtotal</p>
              <p className="number">
                {formatNumber(getCartTotal(boxes).toFixed(2))}
              </p>
            </div>
          )}
          {cupom && (
            <div className="discount">
              <p>Descontos</p>
              <p className="number">
                -{formatNumber(cart.discount.toFixed(2))}
              </p>
            </div>
          )}
          {cart.delivery === "delivery" && (
            <div>
              <p>Valor da entrega</p>
              <p className="number">{formatNumber(deliveryTax)}</p>
            </div>
          )}
        </div>
        <div className="total">
          <p>Valor total do pedido</p>
          <p className="number">{formatNumber(totalWTax)}</p>
        </div>
        <Button
          kind={"primary"}
          label="Finalizar meu pedido"
          onClick={handleCheckout}
        />
      </div>
    </div>
  );
}
