import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { GiftFilled } from "@ant-design/icons";
import { AddCircleRounded } from '@mui/icons-material';

import { showNotification } from '../../helpers/notification'
import { useGlobalState } from "../../hooks/globalState";

export default function AddItensOption({ cart }) {
    const navigate = useNavigate();

    const { updateCart } = useGlobalState();

    return (
        <div
            className="add-more option"
            onClick={() => {
                updateCart("id", cart.id);
                updateCart("itens", cart.itens);
                updateCart("name", cart.name);
                updateCart("message", cart.message);
                navigate("/cardapio");
                showNotification("Montando caixinha!", `Adicionando itens em: ${cart.name}`)
            }}
        >
            <GiftFilled style={{ fontSize: "18pt" }} />
            <p>Adicionar mais itens na caixinha</p>
            <AddCircleRounded />
        </div>
    );
}
