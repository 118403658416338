import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import Brand from "../../components/Brand";
import Button from "../../components/Button";
import Input from "../../components/Input";

import { Lock } from "@mui/icons-material";
import { useGlobalState } from "../../hooks/globalState";
import moment from "moment";

import api from "../../services/api";
import { showNotification } from "../../helpers/notification";
import { updateUserInfo } from "../../helpers/userManagement";

import "./style.css";

export default function NewPassword() {
  const navigate = useNavigate();

  const { isMobile, setLoading, banner } = useGlobalState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isValid, setIsValid] = useState(true);
  const [info, setInfo] = useState({});

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const token = searchParams.get("token");

  useEffect(() => {
    api.post("/get-token", { token }).then((res) => {
      setInfo(res.data.info);
      if (!res.data.info || moment(res.data.info.expDate).isBefore())
        setIsValid(false);
    });
  }, []);

  async function handleSend() {
    if (password !== passwordConfirmation)
      showNotification(
        "As senhas digitadas não são iguais",
        "Por favor, tente novamente"
      );
    else {
      setLoading(true);
      await updateUserInfo(info.id, { password });
      api.post("/delete-token", { token });
      setLoading(false);
      showNotification("Senha atualizada com sucesso!");
      navigate("/login");
    }
  }

  return (
    <div
      id="new-password"
      className="imageBg"
      style={{
        backgroundImage: `url(${isMobile ? banner.url_mobile : banner.url})`,
      }}
    >
      <div className="welcome-new">
        <Brand />
      </div>
      <div className="content-area">
        {isValid ? (
          <>
            <div className="header">
              <h3>Insira sua nova senha!</h3>
            </div>
            <Input
              label="Insira sua nova senha!"
              icon={<Lock className="icon" />}
              placeholder="Crie sua senha"
              lineColor="#9a9a9a"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              label="Repita sua nova senha!"
              icon={<Lock className="icon" />}
              placeholder="Repita sua senha"
              lineColor="#9a9a9a"
              type="password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
            <Button
              kind={"primary"}
              label="Redefinir senha"
              onClick={handleSend}
            />
          </>
        ) : (
          <>
            {" "}
            <div className="header">
              <h3>Ops, parece que esse link não é mais válido 😕</h3>
            </div>
            <Button
              kind={"primary"}
              label="Você pode solicitar outro clicando aqui"
              onClick={() => navigate("/recuperar-senha")}
            />
          </>
        )}
      </div>
    </div>
  );
}
