import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

import googleIcon from "../../images/icons/google.svg";

import { getUserInfo } from "../../helpers/userManagement";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../hooks/globalState";
import { showNotification } from '../../helpers/notification';

export default function Google({ setComponent }) {
  const navigate = useNavigate();
  const [ googleUser, setGoogleUser ] = useState(null);
  const { setLoading, setUser, setKickStartData } = useGlobalState();

  useEffect(() => {
    if (googleUser) {
      attemptLogin();
    }
  }, [ googleUser ]);

  async function getUserProfile(token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let res = await axios.get(
      "https://people.googleapis.com/v1/people/me?personFields=clientData,emailAddresses,names,photos,userDefined",
      config
    );
    setGoogleUser(res.data);
  }

  async function attemptLogin() {
    setLoading(true);
    getUserInfo(
      googleUser.emailAddresses[ 0 ].metadata.source.id, googleUser.emailAddresses[ 0 ].value
    ).then(user => {
      if (user) {
        setUser(user);
        navigate(-1);
      } else {
        setKickStartData({ google: googleUser });
        setComponent("signon");
      }
    }).catch((err) => showNotification("Ops, algo deu errado...", "Por favor, tente novamente mais tarde.")).finally(() => setLoading(false));
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => getUserProfile(tokenResponse.access_token),
  });
  return (
    <img
      src={googleIcon}
      alt=""
      className="icon"
      onClick={() => googleLogin()}
    />
  );
}
