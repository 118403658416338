import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BoxOrder from "../../components/BoxOrder";
import Button from "../Button";

import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { addItensToCart, formatNumber } from "../../helpers/cart";
import { useGlobalState } from "../../hooks/globalState";
import moment from "moment";

import "./style.css";

export default function CartPreview({ active }) {
  const navigate = useNavigate();

  const { cart, boxes, stores } = useGlobalState();
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen((oldOpen) => !oldOpen);
  }

  return open ? (
    <>
      <div className="cart-open">
        <div className="header">
          <div className="metadata">
            <div>
              <p className="name">Resumo do pedido</p>
            </div>
          </div>
          <div onClick={handleClick} className="expand">
            <ExpandLess />
          </div>
        </div>
        <div className="boxes">
          <p className="info">
            {stores.find((store) => store.id == cart.store)?.name}
          </p>
          <p className="info">
            {moment(cart.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
          </p>
          <p className="info">{cart.hour}</p>
          {cart.delivery === "delivery" ? (
            <p className="info">Entrega</p>
          ) : (
            <p className="info">Retirada</p>
          )}
          {cart.delivery === "delivery" && (
            <>
              <p className="info">
                {cart.address.publicPlace}, {cart.address.number}
              </p>
              {cart.address.adjunct && (
                <p className="info">{cart.address.adjunct}</p>
              )}
              <p className="info">
                {cart.address.neighborhood}, {cart.address.city}
              </p>
            </>
          )}
          {boxes.map((box) => (
            <BoxOrder
              order={box}
              active={false}
              isUnmutable
              isExpandable={false}
            />
          ))}
        </div>
        <Button
          kind={"primary"}
          label={"Fazer alterações"}
          onClick={() => navigate("/carrinho")}
        />
      </div>
    </>
  ) : (
    <div className={`cart-closed ${active ? "active" : ""}`}>
      <div className="metadata">
        <div>
          <p className="name">Resumo do pedido</p>
        </div>
      </div>
      <div onClick={handleClick} className="expand">
        <ExpandMore />
      </div>
    </div>
  );
}
