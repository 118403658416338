export const closedDays = [
  "2023-12-24",
  "2023-12-25",
  "2023-12-30",
  "2023-12-31",
  "2024-01-01",
  "2024-01-02",
  "2024-01-03",
  "2024-01-04",
  "2024-01-05",
];
