import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import Avatar from "@mui/material/Avatar";
import DeliveryAdressess from "../../components/DeliveryAdressess";
import AddAddressOption from "../../components/AddAddressOption";

import { ArrowBack } from "@mui/icons-material";
import { useGlobalState } from "../../hooks/globalState";
import { showNotification } from "../../helpers/notification";
import { storageSetItem } from "../../helpers/storage";
import { updateUserInfo } from "../../helpers/userManagement";

import "./style.css";

export default function ProfileAddresses() {
  const navigate = useNavigate();

  const { user, loadedData, setLoading, setUser, isLoggedIn } =
    useGlobalState();
  const [address, setAddress] = useState({});
  const [newAddress, setNewAddress] = useState(false);
  const [newChanges, setNewChanges] = useState(false);

  useEffect(() => {
    if (loadedData && (!user || !user.id || !isLoggedIn)) navigate("/");
  }, [loadedData]);

  async function handleSaveAddress() {
    setLoading(true);
    const userId = user.id;
    let tmpUser = { ...user };
    if (newAddress) tmpUser = await handleNewAddress();
    storageSetItem("user", tmpUser);
    await updateUserInfo(userId, tmpUser);
    setLoading(false);
    setNewAddress(false);
    setAddress({});
    showNotification("Alterações salvas com sucesso!");
  }

  async function handleNewAddress() {
    const tmpUser = { ...user };
    const tmpAddresses = tmpUser.addresses ? JSON.parse(tmpUser.addresses) : [];
    tmpAddresses.push(address);
    tmpUser.addresses = JSON.stringify(tmpAddresses);
    setUser(tmpUser);
    return tmpUser;
  }

  return (
    <div id="profile-addresses" className="payment profile">
      <div className="header">
        <Avatar onClick={() => navigate(-1)}>
          <ArrowBack className="icon" />
        </Avatar>
      </div>
      <div className="content">
        <DeliveryAdressess
          setNewAddress={setNewAddress}
          address={address}
          setAddress={setAddress}
          profile
          setNewChanges={setNewChanges}
        />
        <div className="new-address-container">
          <AddAddressOption
            newAddress={newAddress}
            setNewAddress={setNewAddress}
            address={address}
            setAddress={setAddress}
          />
        </div>
      </div>
      {(newChanges || newAddress) && (
        <div className="bottom">
          <Button
            kind={"primary"}
            label="Salvar alterações"
            onClick={handleSaveAddress}
          />
        </div>
      )}
    </div>
  );
}
