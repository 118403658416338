import React, { useEffect, useState } from "react";

import Input from "../../components/Input";
import Chip from "../../components/Chip";
import creditCardType from 'credit-card-type';
import { Checkbox } from "antd";
import moment from 'moment'

import { CreditCard } from '@mui/icons-material'
import { creditCardsTypes } from '../../helpers/vars';

import "./style.css";

export default function CardForm({
    paymentMethod,
    setPaymentMethod,
    saveCard, setSaveCard, profile
}) {

    const [ cardBrand, setCardBrand ] = useState(null)

    useEffect(() => {
        let card = creditCardType(paymentMethod.cardNumber);
        if (card.length > 0) {
            card = card[ 0 ]
            setCardBrand(creditCardsTypes[ card.type ])
            handleEdit("brand", creditCardsTypes[ card.type ].enum)
        }
    }, [ paymentMethod.cardNumber ]);

    useEffect(() => {
        let date = moment(paymentMethod.expirationDate, 'MM/YYYY')
        const shallowClone = { ...paymentMethod };
        shallowClone.expirationMonth = date.get('month') + 1
        shallowClone.expirationYear = date.get('year')
        setPaymentMethod(shallowClone);
    }, [ paymentMethod.expirationDate ]);

    function handleEdit(field, value) {
        const shallowClone = { ...paymentMethod };
        shallowClone[ field ] = value;
        setPaymentMethod(shallowClone);
    }

    return (
        <div id='address-form' className="fields">
            <div className="title">
                <h1>Informações de pagamento</h1>
            </div>
            <Input
                value={paymentMethod.cardNumber}
                icon={<CreditCard className="icon" />}
                type=""
                placeholder="Número do cartão"
                lineColor="#9a9a9a"
                onChange={(e) => handleEdit("cardNumber", e.target.value)}
                rightIcon={cardBrand ? <img src={cardBrand.svg} className="brand" /> : null}
            />

            <Input
                value={paymentMethod.cardholderName}
                icon={<CreditCard className="icon" />}
                placeholder="Nome completo"
                lineColor="#9a9a9a"
                onChange={(e) => handleEdit("cardholderName", e.target.value)}
            />
            <Input
                id="expirationDate"
                icon={<CreditCard className="icon" />}
                placeholder="mm/AA"
                lineColor="#9a9a9a"
                onChange={(e) =>
                    handleEdit("expirationDate", e.target.value)
                }
                date={true}
            />
            <Input
                value={paymentMethod.cvv}
                icon={<CreditCard className="icon" />}
                placeholder="CVV"
                lineColor="#9a9a9a"
                onChange={(e) => handleEdit("cvv", e.target.value)}
            />
            <Input
                id='cpf'
                value={paymentMethod.cardholderDocument}
                icon={<CreditCard className="icon" />}
                placeholder="CPF do titular"
                lineColor="#9a9a9a"
                onChange={(e) => handleEdit("cardholderDocument", e.target.value)}
            />
            {!profile && <div className='checkbox-container'>
                <Checkbox className="checkbox" checked={saveCard} onChange={e => setSaveCard(e.target.checked)} />
                <label className="checkbox-label" >Salvar este cartão para compras futuras</label>
            </div>}

        </div>
    );
}
