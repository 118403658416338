import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Input from "../../Input";
import Button from "../../Button";
import AddressForm from "../../../forms/AddressForm";

import { Phone, Person, Lock, Email } from "@mui/icons-material";
import { register } from "../../../helpers/userManagement";
import { cpfRegex } from "../../../helpers/regexPatterns";
import { useGlobalState } from "../../../hooks/globalState";
import { showNotification } from "../../../helpers/notification";

import "./style.css";

export default function SignOn({ registerUserData, setRegisterUserData }) {
  const navigate = useNavigate();

  const { setLoading, setUser, kickStartData, setIsLoggedIn } =
    useGlobalState();

  const [showComplementary, setShowComplementary] = useState(false);
  const [readyToProceed, setReadyToProceed] = useState(false);
  const [insertedDataOk, setInsertedDataOk] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [insertedPasswordOk, setInsertedPasswordOk] = useState(false);

  const [address, setAddress] = useState({});

  const requiredKeys = [
    "first_name",
    "last_name",
    "email",
    "mobile",
    "cpf",
    "password",
  ];

  useEffect(() => {
    if (kickStartData) {
      const shallowClone = { ...registerUserData };
      if (kickStartData.google) {
        shallowClone.email = kickStartData.google.emailAddresses[0].value;
        shallowClone.first_name = kickStartData.google.names[0].givenName;
        shallowClone.last_name = kickStartData.google.names[0].familyName;
        shallowClone.platformId =
          kickStartData.google.names[0].metadata.source.id;
      } else if (kickStartData.facebook) {
        shallowClone.email = kickStartData.facebook.email;
        shallowClone.first_name = kickStartData.facebook.first_name;
        shallowClone.last_name = kickStartData.facebook.last_name;
        shallowClone.platformId = kickStartData.facebook.userID;
      }
      setRegisterUserData(shallowClone);
    }
  }, []);

  useEffect(() => {
    if (
      !Object.keys(registerUserData)
        .map((key) => {
          if (requiredKeys.some((requiredKey) => requiredKey === key))
            return registerUserData[key];
        })
        .filter((value) => (value) => value !== undefined)
        .some((value) => value === null) &&
      registerUserData.cpf.match(cpfRegex)
    ) {
      setInsertedDataOk(true);
    }
    if (
      registerUserData.password === repeatPassword &&
      registerUserData.password != ""
    )
      setInsertedPasswordOk(true);
  }, [registerUserData]);

  async function handleCreateAccount() {
    if (insertedDataOk && insertedPasswordOk) {
      setLoading(true);
      if (showComplementary)
        registerUserData.addresses = JSON.stringify([address]);
      await register(registerUserData)
        .then((user) => {
          setUser(user);
          setIsLoggedIn(true);
          setLoading(false);
          navigate("/");
        })
        .catch((error) => {
          setLoading(false);
          if (error.response && error.response.status === 409)
            showNotification(
              "Ops, um usuário com este CPF já foi cadastrado.",
              "Por favor, tente novamente."
            );
        });
    } else
      showNotification(
        "Faltando informações...",
        "Por gentileza verifique seus dados!"
      );
  }

  return (
    <div id="signon">
      <div className="fields">
        <Input
          label="Insira seus dados"
          icon={<Person className="icon" />}
          type=""
          placeholder="Seu nome"
          lineColor="#9a9a9a"
          value={registerUserData.first_name}
          onChange={(e) => {
            const shallowClone = { ...registerUserData };
            shallowClone.first_name = e.target.value;
            setRegisterUserData(shallowClone);
          }}
        />
        <Input
          icon={<Person className="icon" />}
          type=""
          placeholder="Seu sobrenome"
          lineColor="#9a9a9a"
          value={registerUserData.last_name}
          onChange={(e) => {
            const shallowClone = { ...registerUserData };
            shallowClone.last_name = e.target.value;
            setRegisterUserData(shallowClone);
          }}
        />
        <Input
          icon={<Email className="icon" />}
          placeholder="Seu melhor email"
          lineColor="#9a9a9a"
          value={registerUserData.email}
          onChange={(e) => {
            const shallowClone = { ...registerUserData };
            shallowClone.email = e.target.value;
            setRegisterUserData(shallowClone);
          }}
        />
        <Input
          id="phoneInput"
          icon={<Phone className="icon" />}
          placeholder="Seu número de celular"
          lineColor="#9a9a9a"
          value={registerUserData.mobile}
          onChange={(e) => {
            const shallowClone = { ...registerUserData };
            shallowClone.mobile = e.target.value;
            setRegisterUserData(shallowClone);
          }}
        />
        <Input
          id="cpfInput"
          icon={<Person className="icon" />}
          placeholder="Seu CPF"
          lineColor="#9a9a9a"
          value={registerUserData.cpf}
          onChange={(e) => {
            const shallowClone = { ...registerUserData };
            shallowClone.cpf = e.target.value;
            setRegisterUserData(shallowClone);
          }}
        />
        <Input
          icon={<Lock className="icon" />}
          placeholder="Crie uma senha"
          lineColor="#9a9a9a"
          type="password"
          value={registerUserData.password}
          onChange={(e) => {
            const shallowClone = { ...registerUserData };
            shallowClone.password = e.target.value;
            setRegisterUserData(shallowClone);
          }}
        />
        <Input
          id={"repeatPasswordInput"}
          icon={<Lock className="icon" />}
          placeholder="Repita sua senha"
          lineColor="#9a9a9a"
          type="password"
          value={repeatPassword}
          onChange={(e) => {
            setRepeatPassword(e.target.value);
          }}
          onBlur={() => {
            if (registerUserData.password != "" && repeatPassword != "") {
              if (registerUserData.password != repeatPassword) {
                showNotification(
                  "As senhas inseridas não correspondem...",
                  "Por gentileza verifique seus dados!"
                );
                setInsertedPasswordOk(false);
                setRepeatPassword("");
              } else {
                setInsertedPasswordOk(true);
              }
            } else {
              setInsertedPasswordOk(false);
              setRepeatPassword("");
            }
          }}
        />
        <div className="footer">
          <label className="checkbox-label">
            Ao criar uma conta você concorda com os{" "}
            <a onClick={() => navigate("/legal/termos")}>Termos e condições</a>{" "}
            e a{" "}
            <a onClick={() => navigate("/legal/privacidade")}>
              Política de privacidade
            </a>
          </label>
        </div>
      </div>
      <div className="common other-options">
        {!showComplementary ? (
          !readyToProceed && (
            <div id="showComplementaryControls">
              <label>Gostaria de inserir seu endereço neste momento?</label>
              <Button
                kind="primary"
                label="Quero inserir já"
                onClick={() => {
                  setShowComplementary(true);
                  setReadyToProceed(true);
                }}
              />
              <Button
                kind="secondary"
                label="Lembre-me mais tarde"
                onClick={handleCreateAccount}
              />
            </div>
          )
        ) : (
          <div id="complementaryForm">
            <div className="line" />
            <AddressForm address={address} setAddress={setAddress} />
          </div>
        )}
        {readyToProceed && (
          <Button
            kind="primary"
            label="Criar minha conta"
            onClick={handleCreateAccount}
          />
        )}
      </div>
    </div>
  );
}
