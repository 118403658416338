import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "../../components/Button";
import PaymentMethods from "../../components/PaymentMethods";
import AddCardOption from "../../components/AddCardOption";

import { formatNumber, getCartTotal, arrangeCart } from "../../helpers/cart";
import {
  getTokens,
  createCharge,
  createPixCharge,
  createCard,
} from "../../helpers/safraPay/safraPay";
import { useGlobalState } from "../../hooks/globalState";
import { showNotification } from "../../helpers/notification";
import { chargeStatus } from "../../helpers/vars";
import { storageSetItem } from "../../helpers/storage";
import { logToLoggly } from "../../helpers/log";

import api from "../../services/api";

import "./style.css";

export default function Failure() {
  const { cart, boxes, setLoading, setCart, setBoxes, user, cupom } =
    useGlobalState();
  const [paymentMethod, setPaymentMethod] = useState("pix");
  const [newCard, setNewCard] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const { refusedPaymentMethod } = location.state ? location.state : {};

  const total = getCartTotal(boxes).toFixed(2);

  const deliveryTax = cart.zipSet ? cart.zipSet.price.toFixed(2) : "0,00";
  const totalWTax = (parseFloat(total) + parseFloat(deliveryTax)).toFixed(2);

  useEffect(() => {
    if (!refusedPaymentMethod) navigate("/novo-pedido");
  }, []);

  async function handleCheckout() {
    try {
      setLoading(true);
      await getTokens();

      let amount = parseInt(
        (parseFloat(total) + parseFloat(deliveryTax)) * 100
      );
      let resultingCart = arrangeCart(cart, boxes, user, cupom);
      let res;
      if (paymentMethod === "pix")
        res = await createPixCharge(user.safraId, amount);
      else res = await createCharge(user.safraId, paymentMethod, amount);

      if (paymentMethod === "pix" || res.charge.transactions[0].isApproved) {
        let { data } = await api.post(`order`, {
          cart: resultingCart,
          paymentMethod: "card",
          safraId: res.charge.id,
          safraStatus: chargeStatus[res.charge.chargeStatus],
        });
        if (newCard && saveCard) createCard(user.safraId, paymentMethod);
        setCart(null);
        setBoxes(null);
        storageSetItem("cart", null);
        storageSetItem("boxes", null);
        setLoading(false);
        navigate(`/checkout/success`, {
          state: {
            isPix: paymentMethod === "pix",
            base64: res.charge.transactions[0].qrCodeBase64,
            code: res.charge.transactions[0].qrCode,
            orderId: data.order.id,
          },
        });
      } else
        navigate(`/checkout/failure`, {
          state: { refusedPaymentMethod, cupom },
        });
    } catch (error) {
      console.log("ERRO", error);
      logToLoggly("erro ao processar pagamento", error);
      setLoading(false);
      showNotification(
        "Ocorreu um erro ao processar seu pedido.",
        "Por favor, tente novamente."
      );
    }
  }

  return (
    <div id="failure">
      <div className="header">
        {/* <img
          className="controls"
          src={arrow}
          alt=""
          onClick={() => navigate("/carrinho")}
        /> */}
        Ops, <br />
        Pagamento não aprovado
      </div>
      {/* <div className="welcome-msg">
 
      </div> */}
      <div className="content">
        <div className="payment-methods">
          <div className="title">
            <h1>Forma de pagamento:</h1>
          </div>
          <div className="item refused">
            <div className="infos">
              <h1>
                Cartão final{" "}
                {refusedPaymentMethod.cardNumber.substr(
                  refusedPaymentMethod.cardNumber.length - 4
                )}
              </h1>
            </div>
          </div>
        </div>
        <PaymentMethods
          setNewCard={setNewCard}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
          refusedPaymentMethod={refusedPaymentMethod}
        />

        <div className="new-card-container">
          <AddCardOption
            newCard={newCard}
            saveCard={saveCard}
            setNewCard={setNewCard}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            setSaveCard={setSaveCard}
          />
        </div>
      </div>
      <div className="bottom">
        <div className="desc-price">
          {cart.delivery === "delivery" && (
            <div>
              <p>Subtotal</p>
              <p className="number">
                {formatNumber(getCartTotal(boxes).toFixed(2))}
              </p>
            </div>
          )}
          {cupom && (
            <div className="discount">
              <p>Descontos</p>
              <p className="number">
                -{formatNumber(cart.discount.toFixed(2))}
              </p>
            </div>
          )}
          {cart.delivery === "delivery" && (
            <div>
              <p>Valor da entrega</p>
              <p className="number">{formatNumber(deliveryTax)}</p>
            </div>
          )}
        </div>
        <div className="total">
          <p>Valor total do pedido</p>
          <p className="number">{formatNumber(totalWTax)}</p>
        </div>
        <Button
          kind={"primary"}
          label="Finalizar meu pedido"
          onClick={handleCheckout}
        />
      </div>
    </div>
  );
}
