import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useSmoothScroll from "react-smooth-scroll-hook";

import {
  Store,
  Search,
  Upcoming,
  Create,
  DehazeTwoTone,
} from "@mui/icons-material";

import Chip from "../../components/Chip";
import Button from "../../components/Button";
import Product from "../../components/Product";
import FinishOrder from "../../components/FinishOrder";
import Avatar from "@mui/material/Avatar";
import logo from "../../images/icons/logo.svg";

import {
  filterBySearch,
  filterProductsByCategory,
  filterProductsByFavorites,
  filterProductsByLimitedEdition,
  clearFilters,
  capitalizeString,
} from "../../helpers/filters";
import { useGlobalState } from "../../hooks/globalState";
import { getCartQtt } from "../../helpers/cart";

import moment from "moment";
import "moment/locale/pt-br";

import { sleep } from "../../helpers/vars";

import "./style.css";

moment.locale("pt-br");

export default function Products() {
  const navigate = useNavigate();

  const {
    user,
    setShowExplore,
    showExplore,
    boxes,
    cart,
    products,
    filtered,
    setFiltered,
    categories,
    loadedData,
    isMobile,
    setShowCityModal,
    stores,
  } = useGlobalState();

  const [showButton, setShowButton] = useState(false);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [activeUser, setActiveUser] = useState(false);

  const [filterCategory, setFilterCategory] = useState(-1);

  const [search, setSearch] = useState("");
  const [shippingText, setShippingText] = useState(null);

  const ref = useRef();
  const { scrollTo } = useSmoothScroll({
    ref,
    speed: 5,
    direction: "x",
  });

  async function scrollCategories() {
    await sleep(1000);
    scrollTo(`#category-${categories.length - 1}`);
    await sleep(1000);
    scrollTo(`#category-0`);
  }

  useEffect(() => {
    if (isMobile && loadedData) scrollCategories();
  }, [loadedData]);

  useEffect(() => {
    if (filterCategory)
      filterProductsByCategory(
        filterCategory,
        products,
        setSearch,
        setFiltered
      );
  }, [filterCategory, products]);

  useEffect(() => {
    if (boxes.some((cart) => cart.itens && cart.itens.length > 0))
      setShowButton(true);
    else setShowButton(false);
  }, [boxes]);

  useEffect(() => {
    if (search !== "")
      filterBySearch(search, products, setFiltered, setFilterCategory);
    else clearFilters(products, setFiltered, setFilterCategory, setSearch);
  }, [search, products]);

  useEffect(() => {
    setShippingText(getShippingText());
  }, [cart.delivery, cart.store]);

  function getShippingText() {
    if (cart.delivery === "delivery" && cart.store)
      return `Entrega realizada pela loja ${
        stores.find((store) => store.id == cart.store)?.name
      }`;
    if (cart.delivery === "withdrawal" && cart.store)
      return `Retirar na loja ${
        stores.find((store) => store.id == cart.store)?.name
      }`;
    return "Clique aqui para escolher como deseja receber seu pedido";
  }

  return (
    <div id="products">
      <div className="header">
        <div className="icon" onClick={() => navigate("/")}>
          <object data={logo} type="image/svg+xml" />
        </div>
        <div className="explore" onClick={() => setShowExplore(true)}>
          <DehazeTwoTone className="icon-explore" />
        </div>
      </div>
      <div className="welcome-msg">
        Vamos montar <br />
        sua caixinha da alegria!
      </div>
      <div className="delivery-type">
        <div
          id="orderInfoButton"
          className="select selected"
          onClick={() => {
            setShowFinishModal(true);
            setActiveUser(true);
          }}
        >
          <Create />
          <p>{cart.name ? cart.name : "Dê um nome para a sua caixinha"}</p>
        </div>
        <div
          id="orderInfoButtonHighlighted"
          className="select disabled"
          onClick={() => setShowCityModal(true)}
        >
          <Store />
          <p>{shippingText}</p>
          {cart.date && <p>{moment(cart.date, "YYYY-MM-DD").format("LL")}</p>}
        </div>
      </div>
      <div className="search">
        <input
          id="searchBar"
          placeholder="O que sua alegria pede hoje?"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <Search />
      </div>
      <div className="categories" ref={ref}>
        {categories.map((category, key) => (
          <Chip
            id={`category-${key}`}
            label={category.name ? capitalizeString(category.name) : null}
            className={
              filterCategory === category.id
                ? "chip selected category"
                : "chip unselected category"
            }
            icon={category.icon}
            onClick={() => {
              if (category.id === -1)
                clearFilters(
                  products,
                  setFiltered,
                  setFilterCategory,
                  setSearch
                );
              else if (category.id === -2)
                filterProductsByFavorites(
                  products,
                  user.favorites,
                  setSearch,
                  setFiltered,
                  setFilterCategory
                );
              else if (category.id === 0)
                filterProductsByLimitedEdition(
                  products,
                  setSearch,
                  setFiltered,
                  setFilterCategory
                );
              setFilterCategory(category.id);
            }}
          />
        ))}
      </div>
      {filtered.length > 0 && (
        <div className="products">
          {filtered.map((product, index) => (
            <Product product={product} key={`product-${product.id}`} />
          ))}
        </div>
      )}
      {filtered.length === 0 && (
        <div className="empty common">
          <Upcoming />
          <p>Não encontramos nada :(</p>
        </div>
      )}
      <div className="btn-space" />
      {showButton && !showExplore && !showFinishModal && (
        <Button
          kind={"primary fixed"}
          label={
            cart.itens && cart.itens.length > 0 ? (
              <>
                Fechar minha caixinha <Avatar>{getCartQtt(cart)}</Avatar>{" "}
              </>
            ) : (
              "Cancelar esta caixinha"
            )
          }
          onClick={() => {
            setActiveUser(false);
            cart.name || (cart.itens && cart.itens.length == 0)
              ? navigate("/carrinho")
              : setShowFinishModal(true);
          }}
        />
      )}
      {showFinishModal && (
        <FinishOrder
          setShowFinishModal={setShowFinishModal}
          activeUser={activeUser}
        />
      )}
    </div>
  );
}
