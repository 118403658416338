import React from "react";
import { useNavigate } from "react-router-dom";

import Brand from "../../components/Brand";
import ContentLogin from "../ContentLogin";
import Button from "../Button";

import { ArrowBackIosNew } from '@mui/icons-material';

export default function WelcomeLogin({ setComponent }) {

  const navigate = useNavigate();

  return (
    <div >
      <div className='welcome-login'>
        <Brand />
      </div>
      <div className="content-area">
        <div className="header">
          <div
            id="backArrow"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosNew className="arrowBack" />
          </div>
          <h3>Acesse sua conta</h3>
        </div>
        <div className="infos">
          <p>Com uma conta Mille Cuca você:</p>
          <p>Salva seus endereços de entrega;</p>
          <p>Salva suas informações de contato;</p>
          <p>Refaz seus pedidos com um toque!</p>
        </div>
        <Button
          kind={"primary"}
          label="Minha conta Mille Cuca"
          onClick={() =>
            setComponent(<ContentLogin higherComponent={setComponent} />)
          }
        />
        <Button
          kind={"secondary"}
          label="Continuar como convidado"
          onClick={() =>
            navigate(-1)
          }
        />
      </div>
    </div>
  );
}
