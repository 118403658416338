import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { DeleteOutline, Upcoming } from "@mui/icons-material";

import { useGlobalState } from "../../hooks/globalState";
import {
  createCustomer,
  getCards,
  getTokens,
  getCustomerByDocument,
  deleteCard,
} from "../../helpers/safraPay/safraPay";
import { updateUserInfo } from "../../helpers/userManagement";

import "./style.css";
import { showNotification } from "../../helpers/notification";
import { logToLoggly } from "../../helpers/log";

export default function PaymentMethods({
  setNewCard,
  setPaymentMethod,
  paymentMethod,
  refusedPaymentMethod,
  profile,
  newUpdate,
}) {
  const navigate = useNavigate();

  const { user, setUser, setForceLogin, loadedData, setLoading } =
    useGlobalState();
  const [cards, setCards] = useState([]);

  function checkPaymentMethod(card) {
    if (!refusedPaymentMethod) return true;
    if (refusedPaymentMethod.id) return refusedPaymentMethod.id !== card.id;
    return !(
      refusedPaymentMethod.cardNumber.slice(-4) === card.cardNumber.slice(-4) &&
      refusedPaymentMethod.expirationMonth == card.expirationMonth &&
      refusedPaymentMethod.expirationYear == card.expirationYear
    );
  }

  useEffect(() => {
    if (loadedData) safraFlow();
  }, [loadedData]);

  useEffect(() => {
    if (newUpdate) setCards((oldCards) => [...oldCards, ...newUpdate]);
  }, [newUpdate]);

  async function safraFlow() {
    setLoading(true);
    if (user) {
      await getTokens();
      if (user.safraId && user.safraId !== "") {
        let result = await getCards(user.safraId);
        setCards(result);
        if (result.length > 0 && checkPaymentMethod(result[0]))
          setPaymentMethod(result[0]);
      } else {
        createCustomer({
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          entityType: 1,
          documentType: 1,
          document: user.cpf.replace(/[\.\-]*/g, ""),
          phone: {
            countryCode: "55",
            areaCode: "11",
            number: "912345678",
            type: 5,
          },
        })
          .then(async (id) => {
            const shallowClone = { ...user };
            shallowClone.safraId = id;
            setUser(shallowClone);
            await updateUserInfo(user.id, shallowClone);
          })
          .catch(async (e) => {
            const customer = await getCustomerByDocument(
              user.cpf.replace(/[\.\-]*/g, "")
            );
            const shallowClone = { ...user };
            shallowClone.safraId = customer.id;
            setUser(shallowClone);
            await updateUserInfo(user.id, shallowClone);
            if (customer.id)
              console.log("deu errado a criação, usuario ja existia no safra");
            else
              logToLoggly(
                "deu errado a criacao e usuario nao existia no safra",
                e
              );
          });
      }
      setLoading(false);
    } else {
      setForceLogin(true);
      navigate("/login");
    }
  }

  async function handleDeleteCard(cardId) {
    setLoading(true);
    deleteCard(cardId)
      .then((res) => {
        setCards(cards.filter((card) => card.id !== cardId));
        showNotification("Cartão removido com sucesso!");
      })
      .catch((err) =>
        showNotification(
          "Ocorreu um erro ao tentar remover o cartão.",
          "Por favor, tente novamente mais tarde."
        )
      )
      .finally((e) => setLoading(false));
  }

  return (
    <div className="payment-methods">
      <div className="title">
        <h1>
          {profile ? "Formas de pagamento cadastradas:" : "Forma de pagamento:"}
        </h1>
      </div>
      {cards.map(
        (card) =>
          (!refusedPaymentMethod || checkPaymentMethod(card)) &&
          profile && (
            <div
              className={
                paymentMethod.id === card.id || profile
                  ? "item selected"
                  : "item"
              }
              onClick={() => {
                setNewCard(false);
                setPaymentMethod(card);
              }}
            >
              <div className="infos">
                <h1>Cartão final {card.cardNumber.slice(-4)}</h1>
                {profile ? (
                  <h3>
                    {card.brandName} Crédito - Vencimento{" "}
                    {card.expirationMonth.toString().padStart(2, "0")}/
                    {card.expirationYear}
                  </h3>
                ) : (
                  <h3>{card.brandName} Crédito - Pagamento pelo app</h3>
                )}
              </div>

              {profile && (
                <div className="actions">
                  <div onClick={() => handleDeleteCard(card.id)}>
                    <DeleteOutline />
                  </div>
                </div>
              )}
            </div>
          )
      )}
      {profile && cards.length === 0 && (
        <div className="empty">
          <Upcoming />
          <p>Nenhuma forma de pagamento encontrada</p>
        </div>
      )}
      {!profile && (
        <div
          className={paymentMethod === "pix" ? "item selected" : "item"}
          onClick={() => {
            setNewCard(false);
            setPaymentMethod("pix");
          }}
        >
          <div className="infos">
            <h1>Pix</h1>
          </div>
        </div>
      )}
    </div>
  );
}
