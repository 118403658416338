import api from "../services/api";

export async function register(userData) {
  const response = await api.post(`/customer/register`, userData);
  return response.data.success.customer;
}

export async function login(userData) {
  const response = await api.post(`/customer/login`, {
    email: userData.email,
    password: userData.password,
  });
  return response.data.customer;
}

export async function getUserInfo(id, email) {
  const response = await api.post(`/customer/${id}`, { email });
  return response.data.user;
}

export async function getUserByEmail(email) {
  const response = await api.get(`/customer/${email}`);
  return response.data.user;
}

export async function updateUserInfo(id, userData) {
  await api.post(`customer/update/${id}`, userData);
}
