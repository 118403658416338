import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import Input from "../../components/Input";
import Avatar from "@mui/material/Avatar";
import Brand from "../../components/Brand";

import { Email } from "@mui/icons-material";

import { storageRemoveItem } from "../../helpers/storage";

import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function Profile() {
  const {
    isMobile,
    setUser,
    user,
    loadedData,
    banner,
    setCart,
    isLoggedIn,
    setIsLoggedIn,
  } = useGlobalState();
  const navigate = useNavigate();

  useEffect(() => {
    if ((!user || !user.id || !isLoggedIn) && loadedData) navigate("/login");
  }, [loadedData]);

  function handleSignOut() {
    storageRemoveItem("user");
    storageRemoveItem("rememberMe");
    storageRemoveItem("cart");
    storageRemoveItem("boxes");
    setCart({
      id: 0,
      name: null,
      message: null,
      itens: [],
    });
    setCart([]);
    setUser(null);
    setIsLoggedIn(false);
    navigate("/login");
  }

  return (
    <div
      id="profile"
      className="imageBg"
      style={{
        backgroundImage: `url(${isMobile ? banner.url_mobile : banner.url})`,
      }}
    >
      <div className="welcome-profile">
        <Brand back={true} />
      </div>
      <div className="common">
        <div className="content-area">
          <Avatar
            alt={user && user.id ? user.full_name : null}
            sx={{
              height: "20vh",
              width: "20vh",
              marginTop: "-10vh",
              position: "fixed",
            }}
          />
          <h3>{user && user.id ? user.full_name : null}</h3>
          <Input
            label="E-mail cadastrado"
            icon={<Email className="icon" />}
            placeholder="email@millecuca.com.br"
            lineColor="#9a9a9a"
            type="email"
            value={user && user.id ? user.email : null}
          />
          <Button
            kind={"first"}
            label="Meus pedidos"
            onClick={() => navigate("/perfil/pedidos")}
          />
          <Button
            kind={"second"}
            label="Formas de pagamento"
            onClick={() => navigate("/perfil/pagamentos")}
          />
          <Button
            kind={"third"}
            label="Endereços cadastrados"
            onClick={() => navigate("/perfil/enderecos")}
          />
          <div className="feedback" onClick={handleSignOut}>
            <p>Sair da minha conta</p>
          </div>
        </div>
      </div>
    </div>
  );
}
