import React, { useState } from "react";
import BoxItens from '../BoxItens';
import DeleteBoxModal from '../DeleteBoxModal';

import "./style.css";


export default function Box({ cart }) {

    const [ open, setOpen ] = useState(false);


    return (
        <>
            <div className='box-header'>
                <h3>{cart.name}:</h3>
                <p onClick={() => setOpen(true)}>excluir</p>
            </div>
            <BoxItens cart={cart} className='box' />
            {open && <DeleteBoxModal id={cart.id} setOpen={setOpen} />}
        </>
    );
}
