import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Brand from "../../components/Brand";
import Button from "../../components/Button";
import Input from "../../components/Input";

import { Email, ArrowBackIosNew } from "@mui/icons-material";
import { useGlobalState } from "../../hooks/globalState";

import { showNotification } from "../../helpers/notification";

import api from "../../services/api";

import "./style.css";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const { isMobile, setLoading, banner } = useGlobalState();
  const [email, setEmail] = useState("");

  function handleSend() {
    if (email === "")
      showNotification(
        "Parece que algo está errado",
        "Preencha seu e-mail corretamente"
      );
    else {
      setLoading(true);
      api
        .post("/recover-password", { email })
        .then((res) => {
          console.log(res);
          showNotification(
            "E-mail enviado com sucesso!",
            "Por favor, cheque sua caixa de entrada"
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 406)
            showNotification(
              "Não foi encontrado nenhum usuário com este e-mail cadastrado",
              "Revise o e-mail digitado e tente novamente"
            );
          else
            showNotification("Ocorreu um erro", "Por favor, tente novamente");
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <div
      id="forgot-password"
      className="imageBg"
      style={{
        backgroundImage: `url(${isMobile ? banner.url_mobile : banner.url})`,
      }}
    >
      <div className="welcome-forgot">
        <Brand />
      </div>
      <div className="content-area">
        <div className="header">
          <div onClick={() => navigate(-1)}>
            <ArrowBackIosNew className="arrowBack" />
          </div>
          <h3>Esqueci a senha 😬</h3>
        </div>
        <div className="infos">
          <p>
            Sem problemas! Informe seu e-mail abaixo e receberá um link para
            cadastrar sua nova senha! Ps. Fique atento à caixa de spam.
          </p>
        </div>
        <Input
          label="Insira seu e-mail"
          icon={<Email className="icon" />}
          placeholder="ex. marcos@gmail.com"
          lineColor="#9a9a9a"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button kind={"primary"} label="Enviar mensagem" onClick={handleSend} />
      </div>
    </div>
  );
}
