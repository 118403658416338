import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import PaymentMethods from "../../components/PaymentMethods";
import AddCardOption from "../../components/AddCardOption";
import Avatar from "@mui/material/Avatar";

import { ArrowBack } from "@mui/icons-material";
import { getTokens, createCard } from "../../helpers/safraPay/safraPay";
import { useGlobalState } from "../../hooks/globalState";
import { showNotification } from "../../helpers/notification";

import "./style.css";

export default function ProfilePayment() {
  const navigate = useNavigate();

  const { cart, user, loadedData, setLoading, isLoggedIn } = useGlobalState();
  const [paymentMethod, setPaymentMethod] = useState("pix");
  const [newCard, setNewCard] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [newUpdate, setNewUpdate] = useState([]);

  useEffect(() => {
    if (loadedData && (!cart || !user || !user.id || !isLoggedIn))
      navigate("/");
  }, [loadedData]);

  async function handleSaveCard() {
    setLoading(true);
    await getTokens();
    createCard(user.safraId, paymentMethod)
      .then((card) => {
        setNewUpdate((oldUpdate) => [...oldUpdate, card]);
        showNotification("Cartão salvo com sucesso!");
        setNewCard(false);
        setPaymentMethod({});
      })
      .catch((err) =>
        showNotification("Ocorreu um erro.", "Por favor, tente novamente")
      )
      .finally(() => setLoading(false));
  }
  return (
    <div id="checkout" className="payment profile">
      <div className="header">
        <Avatar onClick={() => navigate(-1)}>
          <ArrowBack className="icon" />
        </Avatar>
      </div>
      <div className="content">
        <PaymentMethods
          setNewCard={setNewCard}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
          profile
          newUpdate={newUpdate}
        />
        <AddCardOption
          profile
          newCard={newCard}
          saveCard={saveCard}
          setNewCard={setNewCard}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          setSaveCard={setSaveCard}
        />
      </div>
      {newCard && (
        <div className="bottom">
          <Button
            kind={"primary"}
            label="Salvar alterações"
            onClick={handleSaveCard}
          />
        </div>
      )}
    </div>
  );
}
