import axios from "axios";
import { customerGetToken } from "./auth";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_URL}/api`,
});

api.interceptors.request.use(async (config) => {
  const token = customerGetToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
