import React from "react";

import "./style.css";

export default function Button({ kind, label, onClick, className }) {
  return (
    <div className={`button common ${kind} ${className}`} onClick={onClick}>
      <p>{label}</p>
    </div>
  );
}
