import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Brand from "../../components/Brand";
import WelcomeLogin from "../WelcomeLogin";
import SignIn from "./SignIn";
import SignOn from "./SignOn";
import TextButton from "../TextButton";

import { ArrowBackIosNew } from '@mui/icons-material';
import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function ContentLogin({ higherComponent }) {
  const navigate = useNavigate();

  const [ component, setComponent ] = useState("signin");
  const signInClass = component === "signin" ? "active" : "disabled";
  const signOnClass = component === "signon" ? "active" : "disabled";

  const [ loginUserData, setLoginUserData ] = useState({
    email: null,
    password: null,
  });
  const [ registerUserData, setRegisterUserData ] = useState({
    first_name: null,
    last_name: null,
    email: null,
    mobile: null,
    cpf: null,
    password: null,
    addresses: null,
    created_at: null,
    favorites: null,
    phone: null,
    plataformId: null,
    remember_token: null,
    safraId: null,
    updated_at: null,
  });

  return (
    <div className="content-login">
      {component === "signin" && (
        <div className='welcome-login'>
          <Brand />
        </div>
      )}

      <div
        className="common"
        style={{ marginTop: component === "signin" ? "2vh" : "" }}
      >
        <div
          className="content-area"
          style={{ marginTop: component === "signin" ? "15vh" : "" }}
        >
          <div className="header-login">
            <div
              onClick={() => {
                navigate(-2)
              }}
            >
              <ArrowBackIosNew className="arrowBack" />
            </div>
            <TextButton
              kind={signInClass}
              label={"Faça login"}
              onClick={() => setComponent("signin")}
            />
            <TextButton
              kind={signOnClass}
              label={"Cadastre-se"}
              onClick={() => setComponent("signon")}
            />
          </div>
          {component === "signin" ? (
            <SignIn
              loginUserData={loginUserData}
              setLoginUserData={setLoginUserData}
              setComponent={setComponent}
            />
          ) : (
            <SignOn
              registerUserData={registerUserData}
              setRegisterUserData={setRegisterUserData}
            />
          )}
        </div>
      </div>
    </div>
  );
}
