import React, { useEffect, useState } from "react";

import Input from "../../components/Input";
import Chip from "../../components/Chip";

import {
  House,
  LocationCity,
  HolidayVillage,
  Notes,
  Edit,
} from "@mui/icons-material";

import { useGlobalState } from "../../hooks/globalState";
import { showNotification } from "../../helpers/notification";
import { getAddressFromCep, calculateDeliveryTax } from "../../helpers/cep";
import { logToLoggly } from "../../helpers/log";
import "./style.css";

export default function AddressForm({ address, setAddress }) {
  const { updateCart, cart, setLoading, stores } = useGlobalState();
  const [chips, setChips] = useState([
    { label: "Casa", selected: true },
    { label: "Trabalho", selected: false },
    { label: "Parceiro", selected: false },
    { label: "Personalizar", selected: false },
  ]);
  const [openCustomNameInput, setOpenCustomNameInput] = useState(false);

  async function handleCep(cep) {
    setLoading(true);
    try {
      let infos = await getAddressFromCep(cep);
      const shallowClone = { ...address };
      shallowClone.publicPlace = infos.street;
      shallowClone.neighborhood = infos.neighborhood;
      shallowClone.city = infos.city;
      shallowClone.cep = cep;
      setAddress(shallowClone);
    } catch (error) {
      logToLoggly("erro no endereço", error);
      if (cart.delivery === "delivery")
        showNotification(
          "Ops, aconteceu algum erro ao processar seu endereço",
          "Por favor, digite-o manualmente."
        );
      setLoading(false);
    }
    try {
      if (cart.delivery === "delivery") {
        let store = stores.find((s) => s.id == cart.store);
        let shippingInfo = await calculateDeliveryTax(
          cep,
          store.cep,
          cart.store
        );
        if (shippingInfo) updateCart("zipSet", shippingInfo);
        else {
          setAddress({});
          showNotification(
            "Infelizmente, ainda não entregamos nesse endereço",
            "Por favor, tente novamente."
          );
        }
      }
      setLoading(false);
    } catch (error) {
      logToLoggly("erro no calculo do frete", error);
      if (cart.delivery === "delivery")
        showNotification(
          "Ops, aconteceu algum erro ao processar o valor de entrega",
          "Por favor, tente novamente."
        );
      setLoading(false);
    }
  }

  return (
    <div id="address-form" className="fields">
      <div className="title">
        <h1>Endereço de entrega</h1>
      </div>
      <Input
        id={"cep"}
        icon={<Notes className="icon" />}
        type="text"
        placeholder="CEP"
        lineColor="#9a9a9a"
        value={address.cep}
        onChange={(e) => {
          const shallowClone = { ...address };
          shallowClone.cep = e.target.value;
          setAddress(shallowClone);
          if (e.target.value.replace("-", "").length === 8)
            handleCep(e.target.value);
        }}
      />
      <Input
        icon={<House className="icon" />}
        placeholder="Logradouro"
        lineColor="#9a9a9a"
        value={address.publicPlace}
        onChange={(e) => {
          const shallowClone = { ...address };
          shallowClone.publicPlace = e.target.value;
          setAddress(shallowClone);
        }}
      />
      <Input
        icon={<House className="icon" />}
        placeholder="Número"
        lineColor="#9a9a9a"
        value={address.number}
        onChange={(e) => {
          const shallowClone = { ...address };
          shallowClone.number = e.target.value;
          setAddress(shallowClone);
        }}
      />
      <Input
        icon={<HolidayVillage className="icon" />}
        placeholder="Complemento"
        lineColor="#9a9a9a"
        value={address.adjunct}
        onChange={(e) => {
          const shallowClone = { ...address };
          shallowClone.adjunct = e.target.value;
          setAddress(shallowClone);
        }}
      />
      <Input
        icon={<HolidayVillage className="icon" />}
        placeholder="Bairro"
        lineColor="#9a9a9a"
        value={address.neighborhood}
        onChange={(e) => {
          const shallowClone = { ...address };
          shallowClone.neighborhood = e.target.value;
          setAddress(shallowClone);
        }}
      />
      <Input
        icon={<LocationCity className="icon" />}
        placeholder="Cidade"
        lineColor="#9a9a9a"
        value={address.city}
        onChange={(e) => {
          const shallowClone = { ...address };
          shallowClone.city = e.target.value;
          setAddress(shallowClone);
        }}
      />
      <div id="addressChipsMobile">
        <div className="houseTypes">
          {chips.map((chip, i) => {
            return (
              <Chip
                label={chip.label}
                selected={chip.selected}
                key={i}
                onClick={() => {
                  const tmp = [...chips];
                  tmp.forEach((chip) => {
                    chip.selected = false;
                  });
                  tmp[i].selected = true;
                  setChips(tmp);
                  const shallowClone = { ...address };
                  if (chip.label != "Personalizar") {
                    shallowClone.name = chip.label;
                    setOpenCustomNameInput(false);
                  } else {
                    shallowClone.name = "";
                    setOpenCustomNameInput(true);
                  }
                  setAddress(shallowClone);
                }}
              />
            );
          })}
        </div>
        {openCustomNameInput && (
          <Input
            icon={<Edit className="icon" />}
            type=""
            placeholder="Ex. Casa do meu irmão"
            lineColor="#9a9a9a"
            onChange={(e) => {
              const shallowClone = { ...address };
              shallowClone.name = e.target.value;
              setAddress(shallowClone);
            }}
          />
        )}
      </div>
    </div>
  );
}
