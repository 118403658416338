import api from "../services/api";
import moment from "moment";

export async function getProducts(
  setFiltered,
  setProducts,
  setLoading,
  store = null
) {
  setLoading(true);
  const url = store ? `/products?store=${store}` : "/products";
  let { data } = await api.get(url);
  data.sort((a, b) => a.exibitionOrder - b.exibitionOrder);
  if (store)
    data = data.filter(
      (p) =>
        p.isLimited !== 1 ||
        moment().isBetween(p.available_from, p.available_to, null, "[]")
    );
  else
    data = data.filter(
      (p) =>
        p.isLimited !== 1 ||
        moment(p.available_to, "YYYY-MM-DD").isSameOrAfter(moment(), "date")
    );
  data = data.filter((p) => !p.paused);
  setFiltered(data);
  setProducts(data);
  setLoading(false);
}

export function getPosts(setPosts, setFilteredPosts, setLoading) {
  setLoading(true);
  api.get("posts").then((res) => {
    res.data.sort((a, b) => b.position - a.position);
    setPosts(res.data);
    setFilteredPosts(res.data);
    setLoading(false);
  });
}
export async function getStores(setStores) {
  const { data } = await api.get("/stores");
  setStores(data);
}

export async function getCategories(setCategories) {
  const { data } = await api.get("categories");
  setCategories((oldCategories) => [...oldCategories, ...data]);
}

export async function getBanner(setBanner) {
  const { data } = await api.get("banners");
  setBanner(data);
}

export async function checkAvailability(products, store) {
  const { data } = await api.post(`/products/availability?store=${store}`, {
    products,
  });
  return data;
}
