import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Welcome from "./pages/Welcome";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import NewPassword from "./pages/NewPassword";
import Order from "./pages/Order";
import Products from "./pages/Products";
import Product from "./pages/Product";
import Cart from "./pages/Cart";

import Success from "./pages/Success";
import Failure from "./pages/Failure";
import Closed from "./pages/Closed";
import Explore from "./components/Explore";
import Profile from "./pages/Profile";
import News from "./pages/News";
import Home from "./pages/Home";
import Stores from "./pages/Stores";
import About from "./pages/About";

import OrderDate from "./pages/OrderDate";
import OrderAddress from "./pages/OrderAddress";
import OrderPayment from "./pages/OrderPayment";
import OrderLogin from "./pages/OrderLogin";

import ProfileOrders from "./pages/ProfileOrders";
import ProfileAddresses from "./pages/ProfileAddresses";
import ProfilePayment from "./pages/ProfilePayment";

import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

import CityModal from "./components/CityModal";
import { useGlobalState } from "./hooks/globalState";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function RoutesConfig() {
  const { showExplore, loading, showCityModal, setShowCityModal } =
    useGlobalState();

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" caseSensitive={false} element={<Products />} />
        <Route path="/login" caseSensitive={false} element={<Login />} />
        <Route
          path="/recuperar-senha"
          caseSensitive={false}
          element={<ForgotPassword />}
        />
        <Route
          path="/definir-senha"
          caseSensitive={false}
          element={<NewPassword />}
        />
        <Route
          path="/novo-pedido"
          caseSensitive={false}
          element={<Navigate to="/" />}
        />
        <Route path="/cardapio" caseSensitive={false} element={<Products />} />
        <Route path="/:produto" caseSensitive={false} element={<Product />} />
        <Route path="/carrinho" caseSensitive={false} element={<Cart />} />
        <Route
          path="/checkout/success"
          caseSensitive={false}
          element={<Success />}
        />
        <Route
          path="/checkout/failure"
          caseSensitive={false}
          element={<Failure />}
        />
        <Route path="/closed" caseSensitive={false} element={<Closed />} />
        <Route path="/perfil" caseSensitive={false} element={<Profile />} />
        <Route path="/novidades" caseSensitive={false} element={<News />} />
        <Route path="/lojas" caseSensitive={false} element={<Stores />} />
        <Route path="/sobre" caseSensitive={false} element={<About />} />
        {/* <Route path="/cardapio" caseSensitive={false} element={<Home />} /> */}
        <Route
          path="/perfil/pedidos"
          caseSensitive={false}
          element={<ProfileOrders />}
        />
        <Route
          path="/perfil/pagamentos"
          caseSensitive={false}
          element={<ProfilePayment />}
        />
        <Route
          path="/perfil/enderecos"
          caseSensitive={false}
          element={<ProfileAddresses />}
        />
        <Route
          path="/checkout/data"
          caseSensitive={false}
          element={<OrderDate />}
        />
        <Route
          path="/checkout/entrega"
          caseSensitive={false}
          element={<OrderAddress />}
        />
        <Route
          path="/checkout/pagamento"
          caseSensitive={false}
          element={<OrderPayment />}
        />
        <Route
          path="/checkout/verify"
          caseSensitive={false}
          element={<OrderLogin />}
        />
        <Route path="/legal/termos" caseSensitive={false} element={<Terms />} />
        <Route
          path="/legal/privacidade"
          caseSensitive={false}
          element={<Privacy />}
        />
      </Routes>
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={true}>
          <CircularProgress />
        </Backdrop>
      )}
      {showCityModal && <CityModal setOpen={setShowCityModal} />}
      {showExplore && <Explore />}
    </Router>
  );
}
