import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { CaretRightOutlined } from "@ant-design/icons";
import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";

import CustomButton from "../../components/Button";
import FinishOrder from "../../components/FinishOrder";
import OrderDate from "../../components/OrderDate";
import { Collapse, Col, Row } from "antd";

import { CCarousel, CCarouselItem, CImage } from "@coreui/react";

import MetaTags from "react-meta-tags";

import arrow from "../../images/icons/arrow.svg";
import share from "../../images/icons/share.svg";
import heart from "../../images/icons/heart.svg";
import heartFilled from "../../images/icons/heart-filled.svg";

import { handleFavorite } from "../../helpers/favorites";
import { addItemToCart } from "../../helpers/cart";
import { useGlobalState } from "../../hooks/globalState";
import {
  showLoginNotification,
  showNotification,
} from "../../helpers/notification";
import { formatProductName } from "../../helpers/product";
import moment from "moment";
import "./style.css";

const { Panel } = Collapse;

async function handleShare(product, pathname) {
  if (navigator.share) {
    await navigator.share({
      title: `Mille Cuca - ${product.name}`,
      url: `${process.env.PUBLIC_URL}${pathname}`,
      text: "Compra pra mim? 🧡",
    });
  } else {
    showNotification(
      "Parece que o seu navegador não suporta essa funcionalidade :("
    );
  }
}

export default function Product() {
  const navigate = useNavigate();
  const { produto } = useParams();
  const {
    cart,
    updateCart,
    products,
    user,
    setUser,
    setShowCityModal,
    stores,
    setFutureAdd,
    futureAdd,
  } = useGlobalState();

  const { state, pathname } = useLocation();

  const [product, setProduct] = useState(null);
  const [favorite, setFavorite] = useState(false);
  const [qtt, setQtt] = useState(0);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [unavailable, setUnavailable] = useState(false);

  useEffect(() => {
    if (state && state.product) setProduct(state.product);
    else
      setProduct(
        products.find((product) => formatProductName(product.name) == produto)
      );
    setUnavailable(
      !products.find((product) => formatProductName(product.name) == produto)
    );
  }, [products]);

  useEffect(() => {
    if (futureAdd) {
      addItemToCart(futureAdd, cart, updateCart, qtt);
      setFutureAdd(null);
    }
  }, [cart.store]);

  useEffect(() => {
    if (product && user && user.favorites)
      setFavorite(user.favorites.split(",").includes(product.id.toString()));
  }, [product]);

  useEffect(() => {
    if (product) {
      let cartQtt = null;
      if (cart.itens)
        cartQtt = cart.itens.find((item) => item.id == product.id);
      if (cartQtt) setQtt(cartQtt.qtt);
      else setQtt(0);
    }
  }, [cart, product]);

  function getProductName() {
    const words = pathname.replace("/", "").split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  }

  function handleAdd() {
    const shallowClone = { ...product };
    if (!cart.delivery || (!cart.city && !cart.store)) {
      setShowCityModal(true);
      setFutureAdd(shallowClone);
    } else {
      addItemToCart(shallowClone, cart, updateCart, qtt);
      navigate("/cardapio");
    }
  }

  return (
    product && (
      <div id="product">
        <MetaTags>
          <title>Mille Cuca | {product.name}</title>
          <meta
            id="meta-description"
            name="description"
            content={product.description}
          />
          <meta
            id="og-title"
            property="og:title"
            content={`Mille Cuca | ${product.name}`}
          />
          <meta
            id="og-image"
            property="og:image"
            content={`${product.main_image}/${product.image}`}
          />
        </MetaTags>
        {window.innerWidth < 900 ? (
          <CCarousel controls indicators>
            <CCarouselItem>
              <CImage
                className="d-block w-100"
                src={`${product.main_image}/${product.image}`}
                alt=""
              />
            </CCarouselItem>
            <CCarouselItem>
              <CImage
                className="d-block w-100"
                src={`${product.main_image}/${product.sec_image}`}
                alt=""
              />
            </CCarouselItem>
            <CCarouselItem>
              <CImage
                className="d-block w-100"
                src={`${product.main_image}/${product.ter_image}`}
                alt=""
              />
            </CCarouselItem>
          </CCarousel>
        ) : (
          <Row>
            <Col span={8}>
              <img src={`${product.main_image}/${product.image}`} alt="" />
            </Col>
            <Col span={8}>
              <img src={`${product.main_image}/${product.sec_image}`} alt="" />
            </Col>
            <Col span={8}>
              <img src={`${product.main_image}/${product.ter_image}`} alt="" />
            </Col>
          </Row>
        )}
        <div className="options">
          <div onClick={() => navigate(-1)}>
            <img src={arrow} alt="" />
          </div>
          <div onClick={() => handleShare(product, pathname)}>
            <img src={share} alt="" />
          </div>
          <div
            onClick={(e) => {
              if (user && user.id) {
                setFavorite((currentFavorite) => !currentFavorite);
                handleFavorite(user, product.id, !favorite, setUser);
              } else showLoginNotification(navigate);
            }}
          >
            <img src={favorite ? heartFilled : heart} alt="" />
          </div>
        </div>
        <div className="content-area">
          <div className="infos">
            {product.isLimited === 1 && (
              <h4 className="limited-edition">
                Disponível de{" "}
                {moment(product.available_from, "YYYY-MM-DD").format("DD/MM")} a{" "}
                {moment(product.available_to, "YYYY-MM-DD").format("DD/MM")}
              </h4>
            )}
            <h4>{product.category_name}</h4>
            <h1>{product.name}</h1>
            <p>{product.description}</p>
            {window.innerWidth < 900 ? (
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <div className="seeMore">
                    <CaretRightOutlined
                      rotate={isActive ? 90 : 0}
                      className="icon"
                    />
                    <p>{isActive ? "Ver menos" : "Ver mais"}</p>
                  </div>
                )}
                className="site-collapse-custom-collapse"
              >
                <Panel key="1" className="site-collapse-custom-panel">
                  <h4 className="ingredients">Principais ingredientes:</h4>
                  <p>{product.ingredients}</p>
                </Panel>
              </Collapse>
            ) : (
              <>
                {" "}
                <h4 className="ingredients">Principais ingredientes:</h4>
                <p>{product.ingredients}</p>
              </>
            )}
          </div>
          <div className="infos price">
            <div className="value">
              <p>Preço:</p>
              <p>{product.price}</p>
            </div>
            <div className="add-remove">
              {unavailable ? (
                <p>
                  Esse produto não está disponível na loja{" "}
                  {stores.find((store) => store.id == cart.store)?.name} no
                  momento :(
                </p>
              ) : (
                <>
                  <div
                    className={qtt > 0 ? "icon remove" : "iconDisabled"}
                    onClick={(e) => setQtt((oldQtt) => oldQtt - 1)}
                  >
                    <RemoveCircleRounded />
                  </div>
                  {qtt > 0 && <h3>{qtt.toString().padStart(2, "0")}</h3>}
                  <div
                    className="icon add"
                    onClick={() => {
                      setQtt((oldQtt) => oldQtt + 1);
                    }}
                  >
                    <AddCircleRounded />
                  </div>
                </>
              )}
            </div>
          </div>
          {qtt > 0 && (
            <CustomButton
              kind="primary"
              label={"Adicionar à minha caixinha"}
              onClick={handleAdd}
            />
          )}
          {showFinishModal && (
            <FinishOrder setShowFinishModal={setShowFinishModal} />
          )}
          {showModal && (
            <OrderDate setShowModal={setShowModal} product={product} />
          )}
        </div>
      </div>
    )
  );
}
