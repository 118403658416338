import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../Button";
import Google from "../../../SignInMethods/Google";
import Facebook from "../../../SignInMethods/Facebook";
import Input from "../../Input";
import { Checkbox } from "antd";

import { Email, Lock } from "@mui/icons-material";

import { login } from "../../../helpers/userManagement";
import { emailRegex } from "../../../helpers/regexPatterns";
import { useGlobalState } from "../../../hooks/globalState";
import { showNotification } from "../../../helpers/notification";
import { storageSetItem } from "../../../helpers/storage";
import { logToLoggly } from "../../../helpers/log";

import "antd/dist/antd.css";
import "./style.css";

export default function SignIn({
  loginUserData,
  setLoginUserData,
  setComponent,
}) {
  const navigate = useNavigate();

  const {
    user,
    setUser,
    rememberMe,
    setRememberMe,
    setLoading,
    setIsLoggedIn,
  } = useGlobalState();

  const [insertedDataOk, setInsertedDataOk] = useState(false);

  useEffect(() => {
    if (
      Object.values(loginUserData).every((data) => data) &&
      loginUserData.email.match(emailRegex) !== null
    )
      setInsertedDataOk(true);
  }, [loginUserData]);

  async function handleLogin() {
    try {
      if (insertedDataOk) {
        setLoading(true);
        const user = await login(loginUserData);
        setUser(user);
        setIsLoggedIn(true);
        if (rememberMe) {
          storageSetItem("user", user);
          storageSetItem("rememberMe", rememberMe);
        }
        setLoading(false);
        navigate(-1);
      } else
        showNotification(
          "Dados incorretos...",
          "Por gentileza verifique seus dados!"
        );
    } catch (err) {
      logToLoggly("error on login", err);
      if (err.response.status === 401)
        showNotification(
          "Login ou senha incorretos...",
          "Por gentileza verifique seus dados!"
        );
      else
        showNotification(
          "Ops, tivemos um problema...",
          "Por gentileza tente novamente mais tarde!"
        );
      setLoading(false);
    }
  }

  return (
    <div id="signin">
      <div className="fields">
        <Input
          label="Insira seu e-mail"
          type="email"
          icon={<Email className="icon" />}
          placeholder="ex. marcos@gmail.com"
          lineColor="black"
          value={loginUserData.email}
          onChange={(e) => {
            const shallowClone = { ...loginUserData };
            shallowClone.email = e.target.value;
            setLoginUserData(shallowClone);
          }}
        />
        <Input
          label="Insira sua senha"
          type="password"
          icon={<Lock className="icon" />}
          placeholder="sua senha"
          lineColor="black"
          value={loginUserData.password}
          onChange={(e) => {
            const shallowClone = { ...loginUserData };
            shallowClone.password = e.target.value;
            setLoginUserData(shallowClone);
          }}
        />
        <div className="footer">
          <div>
            <Checkbox
              className="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label className="checkbox-label">Lembrar de mim</label>
          </div>
          <label
            className="forgot-password"
            onClick={() => navigate("/recuperar-senha")}
          >
            Esqueceu sua senha?
          </label>
        </div>
      </div>
      <div className="common other-methods">
        <label>Ou entre com:</label>
        <div className="methods">
          <Google setComponent={setComponent} />
          <Facebook setComponent={setComponent} />
        </div>
        <Button kind={"primary"} label="Entrar" onClick={handleLogin} />
      </div>
    </div>
  );
}
