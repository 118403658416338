import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../helpers/userManagement";
import { useGlobalState } from "../../hooks/globalState";
import facebookIcon from "../../images/icons/facebook.svg";
import { showNotification } from '../../helpers/notification';

export default function Facebook({ setComponent }) {
  const navigate = useNavigate();
  const { setLoading, setUser, setKickStartData } = useGlobalState();
  const [ facebookUser, setFacebookUser ] = useState(null);

  useEffect(() => {
    if (facebookUser) attemptLogin();
  }, [ facebookUser ]);

  async function attemptLogin() {
    setLoading(true);
    getUserInfo(facebookUser.id, facebookUser.email).then(user => {
      if (user) {
        setUser(user.success.user);
        navigate(-1)
      } else {
        setKickStartData({ facebook: facebookUser });
        setComponent("signon");
      }
    }).catch(err => showNotification("Ops, algo deu errado...", "Por favor, tente novamente mais tarde.")).finally(() => setLoading(false));
  }

  const responseFacebook = (response) => {
    setFacebookUser(response);
  };

  return (
    <FacebookLogin
      appId="793623184991204"
      fields="email,first_name,last_name,picture"
      scope="public_profile,user_friends,email"
      callback={responseFacebook}
      render={(renderProps) => (
        <img
          src={facebookIcon}
          alt=""
          className="icon"
          onClick={renderProps.onClick}
        />
      )}
    />
  );
}
