import React from "react";
import "./style.css";

export default function Chip({
  id,
  label,
  onClick,
  selected,
  className,
  icon,
}) {
  const classState = selected ? "selected" : "unselected";
  return (
    <div
      id={id}
      className={`chip ${classState} ${className}`}
      onClick={onClick}
    >
      {icon}
      {label && <p>{label}</p>}
    </div>
  );
}
