import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import BoxItens from '../BoxItens';

import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { addItensToCart } from '../../helpers/cart';
import { useGlobalState } from '../../hooks/globalState';

import "./style.css";

export default function BoxOrder({ order, isExpandable, active, isUnmutable }) {

    const navigate = useNavigate();

    const { cart, updateCart, setLoading } = useGlobalState();

    async function handleRedoOrder() {
        setLoading(true);
        addItensToCart(order.itens, cart, updateCart);
        navigate('/carrinho');
        setLoading(false);
    }

    return (
            <div className='box-order-open'>
                < div className='header' >
                    <div className='metadata'>
                        <div>
                            <p className='name'>{order.name}</p>
                        </div>
                    </div>
                </div >
                <BoxItens cart={order} isMultiple={true} isUnmutable={isUnmutable} />
            </div >
    );
}
