import {
  sendDeleteRequest,
  sendGetRequest,
  sendPostRequest,
  sendPutRequest,
} from "./requests";
import bcrypt from "bcryptjs";

const BASE_URL = process.env.REACT_APP_SAFRAPAY_ENDPOINT;
const BCRYPT = process.env.REACT_APP_SAFRAPAY_BCRYPT;
const CREDENTIAL = process.env.REACT_APP_SAFRAPAY_CREDENTIAL;
let tokens = {
  accessToken: "",
  refreshToken: "",
};

export async function getTokens() {
  let salt = bcrypt.genSaltSync(10);
  let hash = bcrypt.hashSync(BCRYPT, salt);
  const authHeaders = {
    merchantCredential: CREDENTIAL,
    Authorization: hash,
  };
  tokens = await sendPostRequest(
    `${BASE_URL}/v2/merchant/auth`,
    {},
    { headers: authHeaders }
  );
}

export async function createCustomer(customer) {
  let res = await sendPostRequest(
    `${BASE_URL}/v2/customer`,
    { customer },
    {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    }
  ).catch((e) => {
    console.log(e);
    return e;
  });
  return res.customer.id;
}

export async function createCard(customerId, card) {
  let res = await sendPostRequest(
    `${BASE_URL}/v2/card`,
    { customerId, card },
    {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    }
  );
  return res.card;
}

export async function deleteCard(cardId) {
  await getTokens();
  await sendDeleteRequest(`${BASE_URL}/v2/card/${cardId}`, {
    headers: {
      Authorization: `Bearer ${tokens.accessToken}`,
      "Content-Type": "application/json",
    },
    data: { foo: "bar" },
  });
}

export async function getCards(customerId) {
  let res = await sendGetRequest(
    `${BASE_URL}/v2/card/byCustomer?id=${customerId}`,
    {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    }
  );
  return res.cards;
}

export async function getCustomer(customerId) {
  let res = await sendGetRequest(`${BASE_URL}/v2/customer?id=${customerId}`, {
    headers: {
      Authorization: `Bearer ${tokens.accessToken}`,
    },
  });
  return res.customer;
}

export async function getCustomerByDocument(document) {
  let res = await sendGetRequest(
    `${BASE_URL}/v2/customer?document=${document}`,
    {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    }
  ).catch((e) => console.log(e));
  return res.customer;
}

export async function createCharge(customerId, card, amount, merchantChargeId) {
  const data = {
    charge: {
      merchantChargeId,
      customer: {
        id: customerId ?? process.env.REACT_APP_DEFAULT_SAFRAID,
      },
      transactions: [
        {
          card,
          paymentType: 2,
          amount,
          installmentNumber: 1,
        },
      ],
      source: 8,
    },
  };
  return sendPostRequest(`${BASE_URL}/v2/charge/authorization`, data, {
    headers: {
      Authorization: `Bearer ${tokens.accessToken}`,
    },
  });
}

export async function getCharge(chargeId) {
  let res = await sendGetRequest(`${BASE_URL}/v2/charge/${chargeId}`, {
    headers: {
      Authorization: `Bearer ${tokens.accessToken}`,
    },
  });
  return res.charge.transactions[0];
}

export async function cancelCharge(chargeId) {
  sendPutRequest(
    `${BASE_URL}/v2/charge/cancelation/${chargeId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    }
  );
}

export async function createPixCharge(customerId, amount, merchantChargeId) {
  const data = {
    charge: {
      merchantChargeId,
      customerId: customerId ?? process.env.REACT_APP_DEFAULT_SAFRAID,
      transactions: [
        {
          amount,
        },
      ],
      source: 1,
    },
  };

  let res = await sendPostRequest(`${BASE_URL}/v2/charge/pix`, data, {
    headers: {
      Authorization: `Bearer ${tokens.accessToken}`,
    },
  });
  return res;
}
