import React, { useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import {
  createTheme,
  alpha,
  getContrastRatio,
  ThemeProvider,
} from "@mui/material/styles";

import { Close } from "@mui/icons-material";
import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

const orangeBase = "#ff8105";
const orangeMain = alpha(orangeBase, 0.7);

const theme = createTheme({
  palette: {
    orange: {
      main: orangeMain,
      light: alpha(orangeBase, 0.5),
      dark: alpha(orangeBase, 0.9),
      contrastText:
        getContrastRatio(orangeMain, "#fff") > 4.5 ? "#fff" : "#111",
    },
  },
});

export default function FidelityModal({ setOpen, count }) {
  const { cart, updateCart, stores } = useGlobalState();
  const [progress, setProgress] = useState(0);

  function handleClose() {
    if (!cart.store) updateCart("delivery", null);
    setOpen(false);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        let incremento = 0;
        if (oldProgress < 30) incremento = 1;
        else if (oldProgress < 50) incremento = 3;
        else if (oldProgress < 80) incremento = 5;
        else incremento = 7;

        // Atualiza o estado da barra de progresso.
        // Se atingir o valor final (count), mantém o valor atual.
        if (oldProgress >= count * 10) return oldProgress;

        return oldProgress + incremento > 100 ? 100 : oldProgress + incremento;
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div id="fidelity">
      <div className="content">
        <div className="close" onClick={handleClose}>
          <Close />
        </div>
        {count === 10 ? (
          <h3>Você ganhou um presente!</h3>
        ) : (
          <h3>
            Faltam <span>{10 - count}</span> pedidos para você ganhar um
            presente especial!
          </h3>
        )}
        <ThemeProvider theme={theme}>
          <LinearProgress
            variant="determinate"
            value={progress}
            color="orange"
          />
        </ThemeProvider>
        {count === 10 && (
          <p>Em breve enviaremos um e-mail com mais informações</p>
        )}
      </div>
    </div>
  );
}
