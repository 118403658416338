import React, { useContext, useState, createContext, useEffect } from "react";

import { storageGetItem, storageSetItem } from "../helpers/storage";
import {
  getProducts,
  getCategories,
  getPosts,
  getBanner,
  getStores,
} from "../helpers/data";

import { Favorite } from "@mui/icons-material";

const GlobalStateContext = createContext({});

export default function GlobalStateProvider({ children }) {
  const isMobile = window.innerWidth < 500;

  const [showExplore, setShowExplore] = useState(false);
  const [showCityModal, setShowCityModal] = useState(false);

  const [banner, setBanner] = useState({ url: "", url_mobile: "" });

  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [kickStartData, setKickStartData] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    if (rememberMe && user && path !== "/perfil/enderecos" && isLoggedIn)
      storageSetItem("user", user);
  }, [user]);

  const [cart, setCart] = useState({
    id: 0,
    name: null,
    message: null,
    itens: [],
  });

  const [boxes, setBoxes] = useState([]);

  const [cupom, setCupom] = useState(null);

  const [cities, setCities] = useState([]);
  const [stores, setStores] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);

  const [availableDates, setAvailableDates] = useState({});

  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const [categories, setCategories] = useState([
    { id: -2, icon: <Favorite /> },
    { id: -1, name: "TODOS" },
    { id: 0, name: "Edição Limitada" },
  ]);

  const [forceLogin, setForceLogin] = useState(true);
  const [loadedData, setLoadedData] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);

  const [futureAdd, setFutureAdd] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cart && cart.store && cart.isOrder === false)
      getProducts(setFiltered, setProducts, setLoading, cart.store);
    else if (cart && cart.store && cart.isOrder === true)
      getProducts(setFiltered, setProducts, setLoading);
  }, [cart.store, cart.date]);

  function updateCart(field, value) {
    setCart((oldCart) => {
      let tmp = { ...oldCart };
      tmp[field] = value;
      return tmp;
    });
  }

  function updateBoxes(cart) {
    let tmp = boxes && boxes.length > 0 ? [...boxes] : [];
    const index = boxes.findIndex(
      (existantCart) => existantCart.id === cart.id
    );
    if (cart.itens && cart.itens.length > 0) {
      if (index != -1) {
        tmp[index] = cart;
      } else {
        tmp.push(cart);
      }
    } else {
      tmp = tmp.filter((existant) => existant.id != cart.id);
    }
    //updating everyCart to have the same delivery info:
    tmp.forEach((currentCart) => {
      currentCart.delivery = cart.delivery;
      currentCart.zipSet = cart.zipSet;
      currentCart.date = cart.date;
    });

    setBoxes(tmp);
  }

  useEffect(() => {
    getBanner(setBanner);
    getPosts(setPosts, setFilteredPosts, setLoading);
    getProducts(setFiltered, setProducts, setLoading);
    getCategories(setCategories);
    getStores(setStores);
    const u = storageGetItem("user");
    if (u && u.id) {
      setUser(u);
      setIsLoggedIn(true);
    }
    if (storageGetItem("rememberMe"))
      setRememberMe(storageGetItem("rememberMe"));
    setLoadedData(true);
  }, []);

  useEffect(() => {
    if (loadedData && cart) {
      updateBoxes(cart);
      if (cart.date && cart.date != selectedDate) setSelectedDate(cart.date);
    }
  }, [cart]);

  useEffect(() => {
    const notAvailable = boxes
      .reduce((allProducts, cart) => {
        allProducts = allProducts.concat(cart.itens);
        return allProducts;
      }, [])
      .filter((item) => {
        return !products.some((product) => product.id == item.id);
      });

    if (notAvailable.length > 0) {
      const tmp = [...boxes];
      tmp.forEach((cart) => {
        cart.itens = cart.itens.filter(
          (item) =>
            !notAvailable.some(
              (notAvailableItem) => notAvailableItem.id == item.id
            )
        );
      });
      setBoxes([...tmp]);
    }
  }, [products]);

  return (
    <GlobalStateContext.Provider
      value={{
        isMobile,
        showExplore,
        setShowExplore,
        user,
        setUser,
        kickStartData,
        setKickStartData,
        cart,
        setCart,
        updateCart,
        boxes,
        setBoxes,
        cities,
        setCities,
        selectedDate,
        setSelectedDate,
        availableDates,
        setAvailableDates,
        products,
        setProducts,
        filtered,
        setFiltered,
        forceLogin,
        setForceLogin,
        rememberMe,
        setRememberMe,
        banner,
        loadedData,
        loading,
        setLoading,
        posts,
        filteredPosts,
        setFilteredPosts,
        categories,
        setCategories,
        showCityModal,
        setShowCityModal,
        stores,
        setStores,
        cupom,
        setCupom,
        futureAdd,
        setFutureAdd,
        isLoggedIn,
        setIsLoggedIn,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}

export function useGlobalState() {
  const context = useContext(GlobalStateContext);

  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }

  return context;
}
