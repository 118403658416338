import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DehazeTwoTone, WhatsApp } from "@mui/icons-material";
import logo from "../../images/icons/logo.svg";

import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function Stores() {
  const navigate = useNavigate();

  const { setShowExplore, stores } = useGlobalState();

  return (
    <div id="stores">
      <div className="header">
        <div className="icon" onClick={() => navigate("/")}>
          <object data={logo} type="image/svg+xml" />
        </div>
        <div className="explore" onClick={() => setShowExplore(true)}>
          <DehazeTwoTone className="icon-explore" />
        </div>
      </div>
      <div className="content">
        {stores.map((store) => (
          <div className="store">
            <div
              className="img"
              style={{ backgroundImage: `url(${store.image_path})` }}
            />
            <div className="infos">
              <h2>{store.name}</h2>
              <a
                className="address"
                target="_blank"
                href={`https://www.google.com/maps?q=${store.address}`}
              >
                {store.address}
              </a>
              <div>
                {store.schedule.split(";").map((schedule) => (
                  <p>{schedule}</p>
                ))}
              </div>
              <a
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=55${store.phone.replace(
                  /\D/gim,
                  ""
                )}&text=Ol%C3%A1!`}
              >
                <WhatsApp className="icon" />
                {store.phone}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
