import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WelcomeLogin from "../../components/WelcomeLogin";
import ContentLogin from "../../components/ContentLogin";

import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function Login() {
  const navigate = useNavigate();
  const [component, setComponent] = useState(<></>);
  const { isMobile, forceLogin, banner, user, isLoggedIn } = useGlobalState();

  useEffect(() => {
    if (user && isLoggedIn) navigate(-1);
    setComponent(
      forceLogin ? (
        <ContentLogin higherComponent={setComponent} />
      ) : (
        <WelcomeLogin setComponent={setComponent} />
      )
    );
  }, []);

  return (
    <div
      id="login"
      className="imageBg"
      style={{
        backgroundImage: `url(${isMobile ? banner.url_mobile : banner.url})`,
      }}
    >
      {component}
    </div>
  );
}
