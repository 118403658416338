import React, { useState } from "react";

import Button from "../Button";

import { Close, EditRounded } from "@mui/icons-material";
import { useGlobalState } from "../../hooks/globalState";
import { updateUserInfo } from "../../helpers/userManagement";

import "./style.css";

export default function PhoneModal({ setShowPhoneModal, handleNext }) {
  const { user, setUser } = useGlobalState();

  const [phone, setPhone] = useState("");

  return (
    <div id="phone-modal">
      <div className="content">
        <div className="close" onClick={() => setShowPhoneModal(false)}>
          <Close />
        </div>
        <h3>
          Para continuar, precisamos de um número de telefone para contato
        </h3>
        <div className="search">
          <input
            placeholder={`(xx) xxxxx-xxxx`}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <EditRounded />
        </div>
        <Button
          kind={"primary"}
          label={"Continuar"}
          onClick={() => {
            setUser((oldUser) => {
              return { ...oldUser, phone, mobile: phone };
            });
            updateUserInfo(user.id, { phone, mobile: phone });
            setShowPhoneModal(false);
            handleNext();
          }}
        />
      </div>
    </div>
  );
}
