import React from "react";
import { useNavigate } from "react-router-dom";

import { TouchApp, Close } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";

import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function ExploreModal() {
  const { showExplore, setShowExplore, user, isLoggedIn } = useGlobalState();
  const navigate = useNavigate();

  const pages = [
    {
      label: "Início",
      onClick: () => {
        navigate("/");
      },
    },
    {
      label: "Seja um franqueado",
      onClick: () => {
        window.open("https://franquia.millecuca.com/", "_blank");
      },
    },
    {
      label: "Meus pedidos",
      onClick: () => {
        navigate("/perfil/pedidos");
      },
    },

    {
      label: "Lojas e horários",
      onClick: () => {
        navigate("/lojas");
      },
    },
    {
      label: "Minha conta",
      onClick: () => {
        user && user.id && isLoggedIn
          ? navigate("/perfil")
          : navigate("/login");
      },
    },
    {
      label: "Sobre nós",
      onClick: () => {
        navigate("/sobre");
      },
    },

    {
      label: "Fale conosco",
      onClick: () => {
        window.open("https://wa.me/5519989493251", "_blank");
      },
    },
  ];

  return (
    <div id="explore" className={!showExplore ? "fadeout" : ""}>
      <div className="close" onClick={() => setShowExplore(false)}>
        <Avatar sx={{ bgcolor: "white" }}>
          <Close />
        </Avatar>
      </div>
      <div className="content-area modal">
        <div className="secondary">
          <div
            className="title"
            onClick={() => {
              setShowExplore(false);
              navigate("/novidades");
            }}
          >
            <h4>Novidades da Mille Cuca</h4>
            <TouchApp className="icon" />
          </div>
        </div>
        <div className="content-area primary">
          {pages.map(({ label, onClick }) => (
            <div className="option">
              <h4
                onClick={() => {
                  setShowExplore(false);
                  onClick();
                }}
              >
                {label}
              </h4>
            </div>
          ))}
          <div className="footer">
            <h5
              onClick={() => {
                setShowExplore(false);
                navigate("/legal/termos");
              }}
            >
              Termos e condições
            </h5>
            <h5
              onClick={() => {
                setShowExplore(false);
                navigate("/legal/privacidade");
              }}
            >
              Política de privacidade
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
