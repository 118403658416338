export const logToLoggly = (message, error) => {
  if (window._LTracker) {
    window._LTracker.push({
      message,
      error,
    });
  } else {
    console.error("Loggly tracker is not initialized");
  }
};
