import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import OrderDate from "../OrderDate";

import { RemoveCircle, AddCircle } from "@mui/icons-material";

import heartUnselected from "../../images/icons/heart-unselected.svg";
import heartSelected from "../../images/icons/heart-selected.svg";

import { useGlobalState } from "../../hooks/globalState";
import { addItemToCart, removeItemFromCart } from "../../helpers/cart";
import { handleFavorite } from "../../helpers/favorites";
import { capitalizeString } from "../../helpers/filters";
import { showLoginNotification } from "../../helpers/notification";
import { formatProductName } from "../../helpers/product";
import moment from "moment";

import "./style.css";

export default function Product({ product, className, disable }) {
  const navigate = useNavigate();
  const {
    cart,
    updateCart,
    user,
    setUser,
    setShowCityModal,
    setFutureAdd,
    futureAdd,
    products,
  } = useGlobalState();
  const [qtt, setQtt] = useState(0);
  const [favorite, setFavorite] = useState(false);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (futureAdd && products.find((p) => p.id == product.id)) {
      addItemToCart(futureAdd, cart, updateCart);
      setFutureAdd(null);
    }
  }, [cart.store]);

  useEffect(() => {
    if (user && user.favorites)
      setFavorite(user.favorites.split(",").includes(product.id.toString()));
  }, [product]);

  useEffect(() => {
    let cartQtt = null;
    if (cart && cart.itens)
      cartQtt = cart.itens.find((item) => item.id == product.id);
    if (cartQtt) setQtt(cartQtt.qtt);
    else setQtt(0);
  }, [cart]);

  function handleAdd() {
    const shallowClone = { ...product };
    addItemToCart(shallowClone, cart, updateCart);
  }

  return (
    <div className={`product ${className}`}>
      <img
        src={`${product.main_image}/${product.capa_image}`}
        alt=""
        onClick={() =>
          navigate(`/${formatProductName(product.name)}`, {
            state: { product, disable },
          })
        }
      />
      <div
        className="favorite"
        onClick={(e) => {
          if (user?.id) {
            setFavorite((currentFavorite) => !currentFavorite);
            handleFavorite(user, product.id, !favorite, setUser);
          } else showLoginNotification(navigate);
        }}
      >
        <img src={favorite ? heartSelected : heartUnselected} alt="" />
      </div>
      <div className="infos">
        {product.isLimited === 1 && (
          <p className="limited-edition">
            De {moment(product.available_from, "YYYY-MM-DD").format("DD/MM")} a{" "}
            {moment(product.available_to, "YYYY-MM-DD").format("DD/MM")}
          </p>
        )}
        <p className="category">{capitalizeString(product.category_name)}</p>
        <p
          className="name"
          onClick={() =>
            navigate(`/${formatProductName(product.name)}`, {
              state: { product, disable },
            })
          }
        >
          {capitalizeString(product.name)}
        </p>
        <div>
          <p className="price">{product.price}</p>
          {!disable && (
            <div id="cartControls">
              <div
                id="remove"
                className={qtt > 0 ? "remove-enabled" : "remove-disabled"}
                onClick={(e) => {
                  const shallowClone = { ...product };
                  removeItemFromCart(shallowClone, cart, updateCart);
                  e.stopPropagation();
                }}
              >
                <RemoveCircle />
              </div>
              {qtt > 0 && (
                <p id="quantity">{qtt.toString().padStart(2, "0")}</p>
              )}
              <div
                className="add"
                onClick={(e) => {
                  if (!cart.delivery || (!cart.city && !cart.store)) {
                    setShowCityModal(true);
                    setFutureAdd({ ...product });
                  } else handleAdd();
                  e.stopPropagation();
                }}
              >
                <AddCircle />
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && <OrderDate setShowModal={setShowModal} product={product} />}
    </div>
  );
}
