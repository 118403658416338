import React from "react";
import "./style.css";

export default function TextButton({ kind, label, onClick }) {
  return (
    <div className="common txt-button" onClick={onClick}>
      <p className={kind}>{label}</p>
      <div className={`rounded detail ${kind}`} />
    </div>
  );
}
