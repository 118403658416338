import axios from "axios";

export function sendPostRequest(url, params, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then((result) => {
        resolve(result.data);
      })
      .catch(async (err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function sendPutRequest(url, params, config) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, config)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sendGetRequest(url, config) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sendDeleteRequest(url, config) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, config)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
