import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BoxOrder from "../../components/BoxOrder";

import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { addItensToCart, formatNumber } from "../../helpers/cart";
import { useGlobalState } from "../../hooks/globalState";

import "./style.css";

export default function OrderVisualization({
  order,
  isExpandable,
  active,
  isUnmutable,
}) {
  const navigate = useNavigate();

  const { cart, updateCart, setLoading } = useGlobalState();
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen((oldOpen) => !oldOpen);
  }

  async function handleRedoOrder() {
    setLoading(true);
    addItensToCart(order.itens, cart, updateCart);
    navigate("/carrinho");
    setLoading(false);
  }

  return open ? (
    <>
      <div className="order-open">
        <div className="header">
          <div className="metadata">
            <div>
              <p className="name">Pedido {order.id}</p>
              <p className="name">-</p>
              <p className="name">{order.date}</p>
              <p className="name">-</p>
              <p className="name">{order.status_name}</p>
            </div>
          </div>
          <div onClick={handleClick} className="expand">
            <ExpandLess />
          </div>
        </div>
        <div className="boxes">
          <p className="info">{order.store_name}</p>
          {order.delivery === "delivery" ? (
            <p className="info">Entrega</p>
          ) : (
            <p className="info">Retirada</p>
          )}
          {order.delivery === "delivery" && (
            <p className="info">{order.full_address}</p>
          )}
          <p className="info">{order.hour}</p>
          {order.boxes.map((box) => (
            <BoxOrder
              order={box}
              active={false}
              isUnmutable
              isExpandable={false}
            />
          ))}
          <div className="desc-price">
            <div>
              <p>Subtotal</p>
              <p className="number">{formatNumber(order.subtotal.toFixed(2))}</p>
            </div>
            <div className="discount">
              <p>Descontos</p>
              <p className="number">{formatNumber(order.discount ?? "0.00")}</p>
            </div>
            {cart.delivery === "delivery" && (
              <div>
                <p>Valor da entrega</p>
                <p className="number">{formatNumber(order.shipping_price.toFixed(2)) }</p>
              </div>
            )}
          <div className="total">
            <p>Valor total do pedido</p>
            <p className="number">{formatNumber(order.total.toFixed(2))}</p>
          </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className={`order-closed ${active ? "active" : ""}`}>
      <div className="metadata">
        <div>
          <p className="name">Pedido {order.id}</p>
          <p className="name">-</p>
          <p className="name">{order.date}</p>
          <p className="name">-</p>
        <p className="name">{order.status_name}</p>
        </div>
        {active && <p className="name description">{order.status}</p>}
      </div>
      {isExpandable && (
        <div onClick={handleClick} className="expand">
          <ExpandMore />
        </div>
      )}
    </div>
  );
}
