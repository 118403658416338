import Fuse from "fuse.js";

export function filterProductsByCategory(categoryId, products, setSearch, setFiltered) {
    if (categoryId > 0) {
        setSearch("");
        setFiltered(
            products.filter((product) => product.category_id === categoryId)
        );
    }
}
export function filterProductsByFavorites(products, userFavorites, setSearch, setFiltered, setFilterCategory) {
    setSearch("");
    setFiltered(
        products.filter((product) => userFavorites && userFavorites.split(',').includes(product.id.toString()))
    );
    setFilterCategory(null);
}
export function filterProductsByLimitedEdition(products, setSearch, setFiltered, setFilterCategory) {
    setSearch("");
    setFiltered(
        products.filter((product) => product.isLimited === 1)
    );
    setFilterCategory(null);
}

export function filterBySearch(search, products, setFiltered, setFilterCategory) {
    const fuse = new Fuse(products, { keys: [ "name", "category_name", "keywords" ] });
    setFiltered(
        fuse.search(search).map((result) => {
            return result.item;
        })
    );
    setFilterCategory(null);
}

export function clearFilters(products, setFiltered, setFilterCategory, setSearch) {
    setFiltered([ ...products ]);
    setFilterCategory(-1);
    setSearch("");
}

export function capitalizeString(str) {
    const words = str.split(" ");
    for (let i in words) words[ i ] = words[ i ].charAt(0).toUpperCase() + words[ i ].slice(1).toLowerCase();
    return words.join(' ')
}