import React from 'react';
import { notification, Button, Space } from "antd";
import { Close } from '@mui/icons-material';

export function showNotification(message, description = "") {
    notification.open({
        className: "boxNotification",
        message,
        description,
        closeIcon: <Close />,
        placement: "top",
        duration: 8,
        width: 600
    });
}

export function showLoginNotification(navigate) {
    const key = `open${Date.now()}`;
    const btn = (
        <Space>
            <Button type="primary" size="small" onClick={() => {
                notification.destroy(key)
                navigate("/login")
            }}>
                Fazer login
            </Button>
        </Space>
    );
    notification.open({
        message: 'Ops, você não está logado',
        description:
            'Acesse sua conta Mille Cuca para favoritar seus produtos',
        btn,
        key,
        closeIcon: <Close />,
        duration: 0,
        width: 600

    });
}