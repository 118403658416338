import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import InputComponent from "../../components/Input";
import Button from "../../components/Button";

import { useGlobalState } from "../../hooks/globalState";
import { getUserByEmail } from "../../helpers/userManagement";
import { showNotification } from "../../helpers/notification";
import { register } from "../../helpers/userManagement";

import { logToLoggly } from "../../helpers/log";
import "./style.css";

export default function OrderLogin() {
  const navigate = useNavigate();

  const { loadedData, user, setUser, setLoading, cart } = useGlobalState();

  const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
  const cpfRegex = new RegExp(/[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}/);

  const [email, setEmail] = useState("");
  const [newUser, setNewUser] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cpf, setCpf] = useState("");
  const [mobile, setMobile] = useState("");

  const nextPage = "/checkout/data";

  useEffect(() => {
    if ((!cart || !cart.delivery) && loadedData) navigate("/novo-pedido");
    else if (user && loadedData) navigate(nextPage);
  }, [loadedData]);

  async function handleCheckUser() {
    if (!emailRegex.test(email))
      showNotification("Ocorreu um erro", "Insira um e-mail válido");
    else {
      setLoading(true);
      getUserByEmail(email)
        .then((usr) => {
          if (usr) {
            setUser(usr);
            navigate(nextPage);
          } else setNewUser(true);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  }

  async function handleRegister() {
    if (firstName === "" || lastName === "")
      showNotification("Ocorreu um erro", "Preencha todos os campos");
    else if (!cpfRegex.test(cpf))
      showNotification("Ocorreu um erro", "Insira um CPF válido");
    else {
      setLoading(true);
      register({
        first_name: firstName,
        last_name: lastName,
        email,
        cpf,
        mobile,
      })
        .then((customer) => {
          setUser(customer);
          navigate(nextPage);
        })
        .catch((e) => {
          logToLoggly("ocorreu um erro ao registrar o cliente", {
            e,
            data: {
              first_name: firstName,
              last_name: lastName,
              email,
              cpf,
              mobile,
            },
          });
          showNotification(
            "Ocorreu um erro ao realizar seu cadastro",
            "Por favor, verifique seus dados e tente novamente."
          );
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <div id="order-login">
      <div className="header">
        <Avatar
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBack className="icon" />
        </Avatar>
      </div>
      <div className="content">
        <div className="welcome-msg">Qual é o seu e-mail?</div>
        <InputComponent
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          lineColor={"black"}
        />
        {newUser ? (
          <div className="first-order-container">
            <p className="first-order-title">
              Identificamos que essa é sua primeira compra!
            </p>
            <p className="first-order-sub">
              Preencha alguns campos para continuarmos:
            </p>
            <InputComponent
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              lineColor={"black"}
              label={"Nome"}
            />
            <InputComponent
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              lineColor={"black"}
              label={"Sobrenome"}
            />
            <InputComponent
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              lineColor={"black"}
              label={"CPF"}
              id={"cpf"}
            />
            <InputComponent
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              lineColor={"black"}
              label={"Celular"}
              id={"phone"}
            />
            <Button
              kind={"primary"}
              label={"Continuar"}
              onClick={handleRegister}
            />
          </div>
        ) : (
          <Button
            kind={"primary"}
            label={"Continuar"}
            onClick={handleCheckUser}
          />
        )}
      </div>
    </div>
  );
}
